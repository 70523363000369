<template>
  <div>
    <v-form @submit="save">
      <v-row dense>
        <v-col cols="12" md="12">
          <v-container>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  label="Add a comment"
                  :value="form.comment"
                  v-model="$v.form.comment.$model"
                  required
                  append-icon="mdi-content-save"
                  @click:append="save()"
                  @blur="$v.form.comment.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-form>

    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table
          fixed-header
          :headers="headers"
          :items="comments"
          item-key="id"
          sort-by="time"
          disable-sort
          no-data-text="No comments yet!"
        >
          <template v-slot:[`item.time`]="{ item }">
            {{ format(new Date(item.timestamp * 1000), 'PP p') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SAVE_TRIP_REQUEST_COMMENT } from '@/store/modules/TripRequest/actions';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { format } from 'date-fns';

export default {
  name: 'TripRequestComments',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: {},
  props: {
    comments: Array,
    tripRequestId: Number,
  },
  data() {
    return {
      format,
      form: { comment: '' },
      headers: [
        { text: 'User', value: 'displayName' },
        { text: 'Comment', value: 'comment' },
        { text: '', value: 'time', width: '180' },
      ],
    };
  },
  validations: {
    form: {
      comment: { required },
    },
  },
  methods: {
    ...mapActions('tripRequest', [SAVE_TRIP_REQUEST_COMMENT]),
    async save() {
      if (this.$v.form.comment.$model != '') {
        try {
          const r = await this.saveTripRequestComment({
            comment: this.$v.form.comment.$model,
            tripRequestId: this.tripRequestId,
          });
          if (r && r.id) {
            this.$myalert.success('Comment saved');
            this.$emit('refreshTripRequest');
            this.form.comment = '';
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      } else this.$myalert.error('Please enter a comment first');
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: #4caf50;
}
</style>
