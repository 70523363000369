<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-btn v-if="!showForm" color="primary" @click="openForm">Send Email</v-btn>
        <v-form @submit="send" ref="form" v-else>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-combobox
                      v-model="$v.form.sendTo.$model"
                      :items="userEmails"
                      item-text="email"
                      label="Send to"
                      multiple
                      outlined
                      chips
                      clearable
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      label="Subject"
                      :value="form.subject"
                      v-model="$v.form.subject.$model"
                      required
                      @blur="$v.form.subject.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      rows="3"
                      auto-grow
                      label="Message"
                      :value="form.content"
                      v-model="$v.form.content.$model"
                      required
                      @blur="$v.form.content.$touch()"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn class="right" @click="showForm = false"> Cancel </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="right" color="green" dark @click="send"> Send </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table
          fixed-header
          :headers="headers"
          :items="emails"
          item-key="id"
          sort-by="time"
          disable-sort
          no-data-text="No emails yet!"
        >
          <template v-slot:[`item.time`]="{ item }">
            {{ format(new Date(item.timestamp * 1000), 'PP p') }}
          </template>
          <template v-slot:[`item.content`]="{ item }">
            <v-tooltip bottom max-width="70%">
              <template v-slot:activator="{ on, attrs }"
                ><p v-bind="attrs" v-on="on" class="truncate">{{ item.content }}</p>
              </template>
              <span>{{ item.content }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SEND_TRIP_REQUEST_EMAIL } from '@/store/modules/TripRequest/actions';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { format } from 'date-fns';

export default {
  name: 'TripRequestEmails',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: {},
  props: {
    emails: Array,
    tripRequestId: Number,
    requesterId: Number,
  },
  data() {
    return {
      format,
      showForm: false,
      headers: [
        { text: 'Sent to', value: 'sendTo' },
        { text: 'Subject', value: 'subject' },
        { text: 'Content', value: 'content' },
        { text: 'Sent by', value: 'displayName' },
        { text: 'Sent at', value: 'time' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['userEmails', 'usersById']),
    form() {
      return { sendTo: [], subject: this.tripRequestId ? `Trip Request #${this.tripRequestId}` : '', content: '' };
    },
  },
  validations: {
    form: {
      sendTo: {},
      subject: { required },
      content: { required },
    },
  },
  methods: {
    ...mapActions('tripRequest', [SEND_TRIP_REQUEST_EMAIL]),
    openForm() {
      const requester = this.usersById[this.requesterId];
      if (requester) this.$v.form.sendTo.$model.push(requester.email);
      this.showForm = true;
    },
    async send() {
      if (this.$v.form.sendTo.$model.length && this.$v.form.subject.$model != '' && this.$v.form.content.$model != '') {
        try {
          const r = await this.sendTripRequestEmail({
            sendTo: this.$v.form.sendTo.$model,
            subject: this.$v.form.subject.$model,
            content: this.$v.form.content.$model,
            tripRequestId: this.tripRequestId,
          });
          if (r && r.id) {
            this.$myalert.success('Email sent');
            this.$refs.form.reset();
            this.$emit('refreshTripRequest');
            this.showForm = false;
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      } else this.$myalert.error('Please complete all fields');
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: #4caf50;
}
.right {
  float: right;
}
.truncate {
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
}
</style>
