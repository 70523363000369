<template>
  <v-dialog v-model="dialog" max-width="800px" no-click-animation persistent>
    <v-card>
      <form>
        <v-card-title>Add Trip Stop</v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-5">
          <v-form ref="form">
            <destination-autocomplete
              :destinationFilters="tripRequestConfig.display.newDestination ? {} : { prospects: 0 }"
              :loading="loadingDestinations"
              :search-input.sync="searchText"
              @destinationChanged="handleNewDestination"
              @internalLoading="(val) => (loadingDestinations = val)"
              hint="Use this field to prefill the form below, or complete the form manually"
              outlined
              persistent-hint
              ref="id"
              v-model="destinationId"
            >
              <template #item="{ on, attrs, item }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-icon color="orange" left v-if="item.place_id">mdi-map-marker-plus</v-icon>
                  <v-icon color="green" left v-else>mdi-map-marker</v-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </template>
            </destination-autocomplete>

            <v-text-field
              :rules="[() => !!$v.form.name.$model || 'Required field']"
              @blur="$v.form.name.$touch()"
              label="Name"
              ref="name"
              required
              v-model.trim="$v.form.name.$model"
            ></v-text-field>

            <v-row class="pt-2">
              <address-form
                :multiple="false"
                :showLatLong="true"
                ref="aform"
                required
                v-model="$v.form.address.$model"
              ></address-form>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="close">Close</v-btn>
          <v-btn color="success" :disabled="$v.$invalid" :loading="saving" @click="save()">Save</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import AddressForm from '@/components/AddressForm.vue';
import DestinationAutocomplete from '@/components/DestinationAutocomplete.vue';
import AddressFormValidations from '@/models/AddressFormValidation.model';
import { SAVE_TRIP_REQUEST_STOP } from '@/store/modules/TripRequest/actions';
import { handleErrors } from '@/util';

const newStop = {
  name: '',
  seq: 0,
};

export default {
  name: 'DestinationStop',
  mixins: [validationMixin],
  inject: ['google', 'eventHub'],
  components: { AddressForm, DestinationAutocomplete },
  props: {
    tripRequest: Object,
    selectedStop: Object,
  },
  computed: {
    ...mapGetters('config', ['tripRequestConfig']),
  },
  data() {
    return {
      destinationId: null,
      dialog: false,
      form: { ...newStop, address: {} },
      handleErrors,
      loadingDestinations: false,
      saving: false,
      searchText: '',
      stopId: 0,
    };
  },
  validations: {
    form: {
      address: AddressFormValidations,
      name: { required },
      seq: {},
    },
  },
  methods: {
    ...mapActions('tripRequest', [SAVE_TRIP_REQUEST_STOP]),
    addAdditionalStop() {
      this.clearForm();
    },
    clearForm() {
      this.$v.$reset();

      this.destination = {};
      this.form = { ...newStop, address: {} };
      this.searchText = '';
      this.stopId = 0;
    },
    close() {
      this.clearForm();
      this.dialog = false;
    },
    handleNewDestination(destination) {
      if (!destination) return;

      this.destination = destination;
      this.form.address = { ...destination.address, name: null } || {};
      this.stopId = destination?.stopId || 0;
    },
    async save() {
      this.saving = true;

      try {
        this.$v.form.address.name.$model = this.$v.form.name.$model;

        await this[SAVE_TRIP_REQUEST_STOP]({
          ...this.$v.form.$model,
          destinationId: this.destination?.id,
          locationId: this.destination?.locationId,
          seq: this.$v.form.seq.$model || this.tripRequest.stops.length,
          simple: !!this.stopId,
          stopId: this.stopId || 0,
          tripRequestId: this.tripRequest.id,
        });

        await new Promise((resolve) => {
          this.eventHub.$emit('refreshTripRequest', this.tripRequest.id, () => {
            this.$nextTick(() => {
              resolve();

              this.close();
              this.$myalert.warning('Be sure to check that your stops are in the correct order');
              this.eventHub.$emit('recalculateRoutes');
            });
          });
        });
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
