<template>
  <v-container fluid>
    <v-form class="full-width">
      <v-row>
        <v-col cols="12" md="12">
          <span class="red--text" v-if="fileRequired">
            <small>*Required</small>
          </span>

          <upload-file
            :hint="fileHint"
            :recordId="tripRequest.id"
            :required="fileRequired"
            @onUpload="onUpload"
            persistentHint
            recordType="triprequest"
            ref="uploadFile"
            showSize
            :readonly="readonly"
          ></upload-file>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <attachments
            :batchId="tripRequest.batchId"
            :recordId="tripRequest.id"
            @onFileDelete="$emit('refresh')"
            recordType="triprequest"
            ref="attachments"
          ></attachments>
        </v-col>
      </v-row>

      <v-row v-if="customFormFields">
        <custom-form-field
          v-for="(cff, i) in customFormFields"
          :key="i"
          :cff="cff"
          :value="tripRequest.customFormFields[cff.id]"
          :readonly="readonly"
          @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
        ></custom-form-field>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadFile from '@/components/UploadFile.vue';
import Attachments from '@/components/Attachments.vue';
import CustomFormField from './CustomFormField.vue';

export default {
  name: 'SupportingDocuments',
  inject: ['eventHub'],
  components: { UploadFile, Attachments, CustomFormField },
  props: {
    tripRequest: Object,
    customFormFields: Array,
    step: Number,
    readonly: Boolean,
  },
  data() {
    return {
      files: [],
      fileValues: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['tripRequestConfig']),
    ...mapGetters('tripRequest', ['isFieldReadonlyAfterApproval']),
    fileRequired() {
      return (
        this.tripRequestConfig.other.requireAttachmentsTripTypes?.includes(this.tripRequest.tripTypeId) ||
        this.tripRequestConfig.other.requireAttachmentsVehicleTypes?.includes(this.tripRequest.vehicleTypeId)
      );
    },
    fileHint() {
      if (this.fileRequired) {
        return 'An attachment is required before this trip request can be submitted';
      }

      return '';
    },
  },
  methods: {
    async onUpload() {
      this.$refs.attachments.fetchItems().then(() => {
        this.$emit('refresh');
      });
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
a {
  text-decoration: none;
}
</style>
