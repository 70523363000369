<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense v-if="leg > 0">
        <v-col cols="12" align="center">
          <v-icon :color="leg == 1 ? 'green' : 'blue'">
            {{ leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}
          </v-icon>
          <span color="yellow" class="font-weight-bold">
            {{ leg == 1 ? 'Split Trip Drop-Off Leg' : 'Split Trip Return Leg' }}
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Trip #</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.id }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Vehicle Type</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{
                  tripRequest.vehicleType === VEHICLE_TYPES.NORMAL
                    ? vehicleTypesById[tripRequest.vehicleTypeId].name
                    : '-'
                }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Pickup</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ pickupText }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">From</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ locationsById[tripRequest.locationId].name }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Total Students</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.totalStudents }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Total Adults</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.totalAdults }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Trip Year</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ new Date(tripRequest.leaveDate).getFullYear() }} - {{ getWeek(new Date(tripRequest.leaveDate)) }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Trip Type</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripTypesById[tripRequest.tripTypeId].name }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Out of County?</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.outOfCounty ? 'Yes' : 'No' }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Return</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ returnText }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Destination</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{
                  destinationsById[tripRequest.destinationId] ? destinationsById[tripRequest.destinationId].name : ''
                }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold"># Drivers needed</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numVehicles }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold"># Special Needs students</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.specialNeedsStudents || 0 }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Trip Zone</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripZone }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Trip Event(s)</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.tripEventIds.map((e) => tripEventsById[e].name).join(', ') }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">Do you need a wheelchair lift?</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">
                {{ tripRequest.needSpecialNeedsVehicle ? 'Yes' : 'No' }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">{{ tripRequestConfig.labels.numSpecialNeedsVehicles }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numSpecialNeedsVehicle }}</div>
            </v-col>
          </v-row>
          <v-row dense v-if="tripRequestConfig.labels.numWheelchairSlots">
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">{{ tripRequestConfig.labels.numWheelchairSlots }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numWheelchairSlot }}</div>
            </v-col>
          </v-row>
          <v-row dense v-if="tripRequestConfig.labels.numSafetyVests">
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">{{ tripRequestConfig.labels.numSafetyVests }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numSafetyVest }}</div>
            </v-col>
          </v-row>
          <v-row dense v-if="tripRequestConfig.labels.numFoldDownSeats">
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold">{{ tripRequestConfig.labels.numFoldDownSeats }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numFoldDownSeat }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-bold"># Vehicles reserved</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 font-weight-normal">{{ vehiclesReserved }}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, getWeek } from 'date-fns';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'AssignmentInfo',
  inject: ['eventHub'],
  mixins: [],
  components: {},
  props: {
    leg: {
      type: Number,
      default: () => 0,
    },
    tripRequest: Object,
    reserveFromLocation: Number,
  },
  data() {
    return {
      getWeek,
      VEHICLE_TYPES,
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    };
  },
  computed: {
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('config', ['tripRequestConfig']),
    tripZone() {
      if (this.tripRequestConfig.other.determineZoneBy == 'request')
        return this.locationsById[this.tripRequest.locationId].zone;
      else if (this.tripRequestConfig.other.determineZoneBy == 'vehicle')
        return this.locationsById[this.reserveFromLocation].zone;
      else return 'no zone';
    },
    vehiclesReserved() {
      return this.tripRequest.assignments.filter((e) => e.vehicleId).length;
    },
    pickupText() {
      let rawLeaveDate, rawLeaveTime;
      switch (this.leg) {
        case 1:
          rawLeaveDate = this.tripRequest.dropOffLegVehPickupDate;
          rawLeaveTime = this.tripRequest.dropOffLegVehPickupTime;
          break;
        case 2:
          rawLeaveDate = this.tripRequest.returnLegVehPickupDate;
          rawLeaveTime = this.tripRequest.returnLegVehPickupTime;
          break;
        default:
          rawLeaveDate = this.tripRequest.leaveDate;
          rawLeaveTime = this.tripRequest.leaveTime;
          break;
      }
      const d = new Date(rawLeaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      const leaveDate = format(dt, 'LLL dd');
      const s = rawLeaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      const leaveTime = `${hour}:${minute} ${ampm} (${this.days[dt.getDay()]})`;
      return leaveDate + ' @ ' + leaveTime;
    },
    returnText() {
      let rawReturnDate, rawReturnTime;
      switch (this.leg) {
        case 1:
          rawReturnDate = this.tripRequest.dropOffLegVehReturnDate;
          rawReturnTime = this.tripRequest.dropOffLegVehReturnTime;
          break;
        case 2:
          rawReturnDate = this.tripRequest.returnLegVehReturnDate;
          rawReturnTime = this.tripRequest.returnLegVehReturnTime;
          break;
        default:
          rawReturnDate = this.tripRequest.returnDate;
          rawReturnTime = this.tripRequest.returnTime;
          break;
      }
      const d = new Date(rawReturnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      const returnDate = format(dt, 'LLL dd');
      const s = rawReturnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      const returnTime = `${hour}:${minute} ${ampm} (${this.days[dt.getDay()]})`;
      return returnDate + ' @ ' + returnTime;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
