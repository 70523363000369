<template>
  <div>
    <div class="container" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
    </div>
    <template v-else>
      <v-banner>
        <template v-if="!tripRequest.submittedUser">
          <h4 class="text-h4">
            Trip Request Details<v-badge
              :value="!tripRequest.id"
              color="error"
              content="New"
              overlap
              offset-x="-10px"
              offset-y="-10px"
            ></v-badge>
          </h4>
          <v-label>Create or edit Trip Request</v-label>
        </template>
        <div v-else-if="tripRequest.status == -1 && tripRequest.submittedUser">
          <v-alert outlined class="amber--text text--darken-1 mb-0" icon="mdi-alert" text prominent dense>
            <h6 class="text-h6 d-inline-block mr-5">Changes Requested by {{ tripRequest.approval.history[0].user }}</h6>
            <span>Please make the required changes and resubmit this trip request.</span><br />
            <v-label>{{ tripRequest.approval.history[0].comments }}</v-label>
          </v-alert>
        </div>
        <div
          class="level-container"
          v-else-if="
            tripRequest.approval &&
            tripRequest.approval.requiredApprovalLevels &&
            tripRequest.approval.requiredApprovalLevels.length
          "
        >
          <template v-for="(level, i) of tripRequest.approval.requiredApprovalLevels">
            <v-tooltip bottom :key="i">
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="`subtitle-2 level ${levelColors[i] == 'blue' ? 'font-weight-bold' : ''}`"
                  :style="`color: ${tripRequest.approval.approved ? 'success' : levelColors[i]}`"
                  :key="i"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="mr-1"
                    color="green"
                    v-show="tripRequest.approval.approved || levelColors[i] == 'green'"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{ level.name }}
                </div>
              </template>
              <span v-html="levelTooltips[i]"></span>
            </v-tooltip>

            <v-icon v-if="i < tripRequest.approval.requiredApprovalLevels.length - 1" :key="i + 5000">
              mdi-arrow-right-bold
            </v-icon>
          </template>
        </div>
      </v-banner>
      <v-container fluid>
        <v-card :class="tripRequest.status == 1 || tripRequest.status == -3 ? 'form nav-padding' : 'form'">
          <v-navigation-drawer
            v-if="tripRequest.status == 1 || tripRequest.status == -3"
            mini-variant
            permanent
            absolute
            right
          >
            <v-list nav>
              <v-tooltip left v-for="item in sideNav" :key="item.name">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item link @click="setCard(item.name)" v-bind="attrs" v-on="on" :ripple="false">
                    <v-list-item-icon>
                      <v-badge :content="item.num" :value="!!item.num" color="red" overlap>
                        <v-icon :color="cardContent == item.name ? 'primary' : ''">{{ item.icon }}</v-icon>
                      </v-badge>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <span>{{ item.label }}</span>
              </v-tooltip>
            </v-list>
          </v-navigation-drawer>

          <v-card-text v-if="cardContent == 'tripRequest'">
            <v-row class="form-wrapper">
              <v-col cols="12" md="12" class="form-content">
                <div class="title" v-if="!duplicated">
                  <v-alert
                    v-if="tripRequest.status == -2 || tripRequest.status == -3"
                    color="red lighten-2"
                    outlined
                    dense
                    text
                    type="warning"
                    prominent
                    ><h2>This trip has been {{ tripRequest.status == -2 ? 'denied' : 'canceled' }}</h2></v-alert
                  >
                  <h6 class="text-h6 inline">Trip Request #{{ tripRequest.id }}</h6>
                  <div class="text-subtitle-2 inline ml-2">{{ submitted }}</div>
                </div>
                <v-col
                  cols="12"
                  md="12"
                  v-if="tripRequest.status == 1 && !tripRequest.approval.approved && tripRequest.recurrence.length > 0"
                >
                  <v-alert outlined type="warning" icon="mdi-autorenew" text>
                    This trip is recurring. Once approved a duplicate trip will be created for each of the selected
                    dates.
                  </v-alert>
                </v-col>

                <div class="section">
                  <div class="text-h6">General</div>
                  <step-general
                    ref="stepGeneral"
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[0]"
                    readonly
                  ></step-general>
                </div>
                <v-divider></v-divider>
                <div class="section">
                  <div class="text-h6">Leave / Return</div>
                  <step-leave-return
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[1]"
                    readonly
                  ></step-leave-return>
                </div>
                <v-divider></v-divider>
                <div class="section">
                  <div class="text-h6">Destination(s)</div>
                  <step-destination
                    :customFormFields="filteredCFFs[2]"
                    :tripRequest="tripRequest"
                    class="section"
                    readonly
                  ></step-destination>
                </div>
                <v-divider></v-divider>
                <div class="section">
                  <div class="text-h6">Attendees</div>
                  <step-attendees
                    class="section"
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[3]"
                    readonly
                  ></step-attendees>
                </div>
                <v-divider></v-divider>
                <div class="section">
                  <div class="text-h6">Transportation</div>
                  <step-transportation
                    class="section"
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[4]"
                    readonly
                  ></step-transportation>
                </div>
                <v-divider></v-divider>
                <div class="section">
                  <div class="text-h6">Funding</div>
                  <step-funding-sources
                    class="section"
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[5]"
                    readonly
                  ></step-funding-sources>
                </div>
                <v-divider></v-divider>
                <div class="section" v-if="showAdditionalInfo">
                  <div class="text-h6">Additional Info</div>
                  <step-additional-info
                    class="section"
                    :tripRequest="tripRequest"
                    :tripRequestConfig="tripRequestConfig"
                    :customFormFields="filteredCFFs[6]"
                    readonly
                  ></step-additional-info>
                </div>
                <v-divider v-if="showAdditionalInfo"></v-divider>
                <div class="section">
                  <div class="text-h6">Supporting Documents</div>
                  <step-supporting-documents
                    class="section"
                    :tripRequest="tripRequest"
                    :customFormFields="filteredCFFs[7]"
                    @refresh="refreshTripRequest"
                    readonly
                  ></step-supporting-documents>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="cardContent == 'assignments'" class="card-content">
            <trip-request-assignments
              ref="assignments"
              :assignments="tripRequest.assignments"
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
            ></trip-request-assignments>
          </v-card-text>
          <v-card-text v-if="cardContent == 'comments'" class="card-content">
            <trip-request-comments
              ref="comments"
              :comments="tripRequest.userComments"
              :tripRequestId="tripRequest.id"
            ></trip-request-comments>
          </v-card-text>
          <v-card-text v-if="cardContent == 'emails'" class="card-content">
            <trip-request-emails
              ref="emails"
              :emails="tripRequest.userEmails"
              :tripRequestId="tripRequest.id"
              :requesterId="tripRequest.submittedUser"
            ></trip-request-emails>
          </v-card-text>
          <v-card-text v-if="cardContent == 'audit'" class="card-content">
            <trip-request-audit
              ref="audit"
              :auditHistory="tripRequest.auditHistory"
              :approval="tripRequest.approval"
            ></trip-request-audit>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { differenceInCalendarDays, format, fromUnixTime } from 'date-fns';
import { GET_TRIP_REQUEST, GET_TRIP_REQUEST_COMPLETE_STATUS } from '@/store/modules/TripRequest/actions';
import { GET_CONFIG } from '@/store/modules/Config/actions';
import { randomString, todayString } from '@/util';

import StepGeneral from './Step.General.vue';
import StepLeaveReturn from './Step.LeaveReturn.vue';
import StepDestination from './Step.Destination.vue';
import StepAttendees from './Step.Attendees.vue';
import StepTransportation from './Step.Transportation.vue';
import StepFundingSources from './Step.FundingSources.vue';
import StepAdditionalInfo from './Step.AdditionalInfo.vue';
import StepSupportingDocuments from './Step.SupportingDocuments.vue';
import TripRequestAssignments from './TripRequestAssignments.vue';
import TripRequestAudit from './Detail.AuditHistory.vue';
import TripRequestComments from './Detail.Comments.vue';
import TripRequestEmails from './Detail.Emails.vue';

export default {
  name: 'RequestDetail',
  inject: ['eventHub'],
  components: {
    StepGeneral,
    StepLeaveReturn,
    StepDestination,
    StepAttendees,
    StepTransportation,
    StepFundingSources,
    StepAdditionalInfo,
    StepSupportingDocuments,
    TripRequestAssignments,
    TripRequestAudit,
    TripRequestComments,
    TripRequestEmails,
  },
  props: ['id'],
  data() {
    return {
      differenceInCalendarDays,
      loading: true,
      saving: false,
      approving: false,
      e1: 1,
      cardContent: 'tripRequest',
      requiredApprovalLevels: [],
      levelColors: {},
      tripRequest: {
        tripEventIds: [],
        approval: {},
        additionalStops: { depart: [], return: [] },
        customFormFields: {},
        fundingSources: [],
        permissions: {},
        status: 0,
      },
      customFormFields: {},
      rolePermissions: {},
      filteredCFFs: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] },
      complete: {
        general: false,
        leaveReturn: false,
        destination: false,
        attendees: false,
        transportation: false,
        fundingSource: false,
        additional: false,
        supporting: false,
      },
      tripRequestConfig: {
        labels: {},
        messages: {},
        display: {
          emergencyContact: [],
          healthConcerns: [],
          awayForLunch: [],
          permissionsSlip: [],
          specialIndicators: [],
        },
        chaperoneRules: {},
        other: {},
      },
      showAdditionalInfo: true,
      preventSubmit: false,
      redirectToDashboard: false,
      redirectToCalendar: false,
      redirectToAssignments: false,
    };
  },
  computed: {
    ...mapGetters('app', ['hasPermissionSlipEnglishTemplate', 'hasPermissionSlipSpanishTemplate']),
    ...mapGetters('user', ['me', 'usersById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('config', ['vehicleOwnerConfig', 'districtWideRates', 'fuelPrices']),
    submitted() {
      return `${this.usersById[this.tripRequest.submittedUser].displayName} - ${format(
        fromUnixTime(this.tripRequest.submittedTimestamp),
        'MMM d, yyyy @ h:mm aaa'
      )}`;
    },
    sideNav() {
      const sn = [
        { name: 'tripRequest', icon: 'mdi-list-box', label: 'Trip Details' },
        // {
        //   name: 'comments',
        //   icon: 'mdi-comment',
        //   label: 'Comments',
        //   num: this.tripRequest ? this.tripRequest.userComments.length : 0,
        // },
        // {
        //   name: 'emails',
        //   icon: 'mdi-email',
        //   label: 'Emails',
        //   num: this.tripRequest ? this.tripRequest.userEmails.length : 0,
        // },
        // { name: 'audit', icon: 'mdi-history', label: 'Audit History' },
      ];
      // if (
      //   this.me.is.superAdmin ||
      //   this.me.is.transportationAdmin ||
      //   this.me.is.limitedAdmin ||
      //   (this.tripRequest.canAssign &&
      //     (this.tripRequest.permissions.canAssign ||
      //       this.tripRequest.permissions.canEditSiteAdmin ||
      //       this.tripRequest.permissions.canEditApprover ||
      //       (this.tripRequest.status == -3 && this.vehicleOwnerConfig.assignCanceled) ||
      //       this.me.is.vehicleOwner ||
      //       this.me.is.specialNeedsVehicleOwner ||
      //       this.me.is.siteAdmin ||
      //       this.me.is.siteAdminReadOnly))
      // )
      //   sn.splice(1, 0, { name: 'assignments', icon: 'mdi-bus', label: 'Assignments' });
      return sn;
    },
    levelTooltips() {
      let tt = {};
      for (let i = 0; i < this.requiredApprovalLevels.length; i++) {
        tt[i] = this.requiredApprovalLevels[i].primaryApprovers
          ? this.requiredApprovalLevels[i].primaryApprovers
              .map((e) => `${e.userDisplayName} (${e.userEmail})`)
              .join('<br>')
          : [];
      }
      return tt;
    },
    duplicated() {
      return this.tripRequest.status == 0 && this.$route.params.id != 0;
    },
    showNext() {
      if (this.tripRequest.status < -1) return false;
      if (this.showAdditionalInfo) return this.e1 < 8;
      else return this.e1 < 7;
    },
    showSaveDraft() {
      return (!this.showNext || this.duplicated) && (this.tripRequest.status === 0 || this.tripRequest.status == -1);
    },
    canReschedule() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        (this.me.is.siteAdmin && this.rolePermissions.siteAdmin.rescheduleTrips) ||
        (this.me.is.approver && this.rolePermissions.approver.rescheduleTrips) ||
        (this.me.is.vehicleOwner && this.rolePermissions.vehicleOwner.rescheduleTrips) ||
        (this.me.is.specialNeedsVehicleOwner && this.rolePermissions.specialNeedsVehicleOwner.rescheduleTrips) ||
        (this.me.is.requester && this.rolePermissions.requester.rescheduleTrips)
      );
    },
    cannotReschedule() {
      if (
        this.tripRequestConfig.other.limitReschedule &&
        differenceInCalendarDays(new Date(this.tripRequest.leaveDate), new Date()) <=
          this.tripRequestConfig.other.limitRescheduleDays
      )
        return true;
      else return false;
    },
    canCreateDuplicate() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAmin ||
        this.me.is.limitedAdmin ||
        this.me.is.siteAdmin ||
        this.me.is.approver ||
        this.me.is.vehicleOwner
      )
        return true;
      if (this.tripRequestConfig && this.tripRequestConfig.display.duplicate) return true;
      else return false;
    },
    showTripEstimator() {
      return this.tripRequestConfig.tripEstimate.display && this.tripRequest.submittedTimestamp;
    },
    showDownloadPermissionSlip() {
      if (
        (this.hasPermissionSlipEnglishTemplate || this.hasPermissionSlipSpanishTemplate) &&
        this.tripRequestConfig.templates.showDownloadPermissionSlip &&
        (this.tripRequest.approved || this.tripRequest.approval.approved)
      )
        return true;
      return false;
    },
    validateSteps() {
      return {
        1: 'validateStepGeneral',
        2: 'validateStepLeaveReturn',
        3: 'validateStepDestination',
        4: 'validateStepAttendees',
        5: 'validateStepTransportation',
        6: 'validateStepFundingSource',
        7: this.showAdditionalInfo ? 'validateStepAdditional' : 'validateStepSupporting',
        8: 'validateStepSupporting',
      };
    },
  },
  created() {
    this.eventHub.$on('leaveTripRequestFormRequested', () => this.leaveTripRequest());
    this.fetchItems();
    if (this.$router.history.current.query.dashboard) this.redirectToDashboard = true;
    if (this.$router.history.current.query.calendar) this.redirectToCalendar = true;
    if (this.$router.history.current.query.assignment) {
      this.redirectToAssignments = true;
      this.cardContent = 'assignments';
    }
  },
  beforeDestroy() {
    this.eventHub.$off('leaveTripRequestFormRequested');
  },
  methods: {
    ...mapActions('tripRequest', [GET_TRIP_REQUEST, GET_TRIP_REQUEST_COMPLETE_STATUS]),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    ...mapMutations('assignment', ['updateAssignment']),
    ...mapActions('config', [GET_CONFIG]),
    async fetchItems() {
      this.tripRequestConfig = await this.getConfig('tripRequestForm');
      this.customFormFields = await this.getConfig('customFormFields');
      this.rolePermissions = await this.getConfig('permissions');
      await this.refreshTripRequest();
      if (this.tripRequest.status > 0) this.eventHub.$emit('recalculateRoutes');
    },
    setCard(option) {
      this.cardContent = option;
    },
    async stepClick(stepNo) {
      if (this.tripRequest.status != 0) {
        this.e1 = !this.showAdditionalInfo && stepNo == 8 ? 7 : stepNo;
      }
    },
    async next() {
      if (
        this.e1 == 2 &&
        this.tripRequest.status == 0 &&
        this.tripRequestConfig.display.overnightOOSRequired &&
        this.tripRequest.outOfState == null
      ) {
        this.$myalert.error('Please complete this section before moving on');
        return;
      } else if (
        this.e1 == 4 &&
        this.tripRequest.status == 0 &&
        this.tripRequestConfig.display.healthConcerns.includes(this.tripRequest.tripTypeId) &&
        this.tripRequestConfig.display.requireHealthConcerns &&
        this.tripRequest.haveHealthConcerns == null
      ) {
        this.$myalert.error('Please complete this section before moving on');
        return;
      }

      if (!this.tripRequest.status || this.tripRequest.status === 0 || this.tripRequest.status == -1) await this.save();
      await this.refreshTripRequest();
      const completeByStep = {
        1: this.complete.general,
        2: this.complete.leaveReturn,
        3: this.complete.destination,
        4: this.complete.attendees,
        5: this.complete.transportation,
        6: this.complete.fundingSource,
        7: this.showAdditionalInfo ? this.complete.additional : this.complete.supporting,
        8: this.complete.supporting,
      };
      if (this.e1 == 3) this.eventHub.$emit('recalculateRoutes');
      if (completeByStep[this.e1]) {
        this.e1 += 1;
        this.eventHub.$emit(this.validateSteps[this.e1], true);
      } else {
        this.$myalert.error('Please complete this section before moving on');
        this.eventHub.$emit(this.validateSteps[this.e1]);
      }
    },
    prev() {
      this.e1 += -1;
    },
    async refreshTripRequest(id) {
      id = id || this.id;
      if (id > 0) {
        const { tripRequest, complete } = await this.getTripRequest(id);
        if (!tripRequest || (tripRequest.status != 0 && !tripRequest.permissions.canView)) {
          this.$router.push('/trip-requests');
          return;
        }
        if (tripRequest.status > 0) {
          this.updateTripRequest(tripRequest);
          if (tripRequest.assignments) for (let a of tripRequest.assignments) this.updateAssignment(a);
        }
        this.tripRequest = tripRequest;
        if (this.tripRequest.id) {
          this.tripRequest.gradeLevels = tripRequest.gradeLevels ? tripRequest.gradeLevels.map((e) => String(e)) : [];
          if (
            this.e1 == 4 &&
            this.tripRequest.status == 0 &&
            this.tripRequestConfig.display.healthConcerns.includes(this.tripRequest.tripTypeId) &&
            this.tripRequestConfig.display.requireHealthConcerns
          )
            this.tripRequest.haveHealthConcerns = null;
        }
        this.complete =
          this.tripRequest.status == 0 || this.tripRequest.status == -1
            ? await this.getTripRequestCompleteStatus({
                ...this.tripRequest,
                step: this.e1,
                includeAdditionalInfo: this.showAdditionalInfo,
              })
            : {
                general: true,
                leaveReturn: true,
                destination: true,
                attendees: true,
                transportation: true,
                fundingSource: true,
                additional: true,
                supporting: true,
              };
        if (this.tripRequest.status == 1) this.setRequiredApprovalLevels(this.tripRequest.approval);
        this.checkAdditionalInfo();
        if (this.tripRequest.status != 0) this.updateTripRequest(this.tripRequest);
      }
      if (this.e1 == 1)
        setTimeout(() => {
          this.eventHub.$emit('stepGeneralRequested');
        }, 100);
      this.loading = false;
    },
    checkAdditionalInfo() {
      if (this.tripRequest.tripTypeId)
        this.showAdditionalInfo =
          this.tripRequestConfig.display.educationalObjective.includes(this.tripRequest.tripTypeId) ||
          this.tripRequestConfig.display.specialIndicators.includes(this.tripRequest.tripTypeId) ||
          this.tripRequestConfig.display.substitute ||
          this.tripRequestConfig.display.permissionSlip.includes(this.tripRequest.tripTypeId) ||
          this.customFormFields[6].length > 0;
    },
    handlePreventSubmit(option) {
      if (!this.me.is.superAdmin && !this.me.is.transportationAdmin) this.preventSubmit = option;
    },
    tripTypeSelected(tripTypeId) {
      for (let i = 0; i < 8; i++)
        this.filteredCFFs[i] = this.customFormFields[i].filter(
          (e) => e.tripTypeIds.includes(tripTypeId) && (e.oos ? this.tripRequest.outOfState : true)
        );
      this.checkAdditionalInfo();
    },
    handleCFFInput({ cffId, value }) {
      this.tripRequest.customFormFields[cffId] = value;
    },
    setRequiredApprovalLevels(approval) {
      this.requiredApprovalLevels = approval.requiredApprovalLevels;
      const awaitingIndex = this.tripRequest.approval.awaitingApproval
        ? this.requiredApprovalLevels.findIndex(
            (e) =>
              e.id == this.tripRequest.approval.awaitingApproval.id &&
              e.name == this.tripRequest.approval.awaitingApproval.name
          )
        : -1;
      for (var i = 0; i < this.requiredApprovalLevels.length; i++) {
        if (i < awaitingIndex) this.levelColors[i] = 'green';
        else if (i > awaitingIndex) this.levelColors[i] = 'black';
        else this.levelColors[i] = 'blue';
      }
    },
    async save(adminSave, draft, doNotAudit) {
      if (this.tripRequest.categoryId || this.tripRequest.locationId)
        try {
          this.saving = true;
          const r = await this.saveTripRequest({
            ...this.tripRequest,
            status: draft ? -1 : this.tripRequest.status,
            savedAsDraft: draft,
            doNotAudit,
          });
          if (r && r.id) {
            if (adminSave) this.$myalert.success('Trip Request saved');
            if (draft) {
              await this.getTripRequests();
              this.$myalert.success(`Trip Request #${r.id} saved as draft`);
              this.$router.push('/trip-requests');
              return;
            }
            await this.refreshTripRequest(r.id);
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      this.saving = false;
    },
    async submit() {
      await this.save();
      this.complete = await this.getTripRequestCompleteStatus({
        ...this.tripRequest,
        step: this.duplicated ? 10 : this.e1,
        includeAdditionalInfo: this.showAdditionalInfo,
      });
      if (
        this.complete.general &&
        this.complete.leaveReturn &&
        this.complete.destination &&
        this.complete.attendees &&
        this.complete.transportation &&
        this.complete.fundingSource &&
        (this.showAdditionalInfo ? this.complete.additional : true) &&
        this.complete.supporting
      ) {
        this.$refs.tripEstimator.estimate();
        this.$refs.acceptance.dialog = true;
      } else this.$myalert.error('Please complete the required fields');
    },
    async approve() {
      this.approving = true;
      await this.save();
      try {
        const r = await this.approveTripRequest({
          tripRequestId: this.tripRequest.id,
          approvalLevelId: this.tripRequest.approval.awaitingApproval.id,
          approved: 1,
        });
        this.refreshTripRequest(this.tripRequest.id);
        this.$myalert.success('Approved');
        if (r.message) setTimeout(() => this.$myalert.warning(r.message), 500);
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.approving = false;
    },
    requestQuote() {
      this.$refs.quote.dialog = true;
    },
    requestChanges() {
      this.$refs.deny.changesNeeded = true;
      this.$refs.deny.dialog = true;
    },
    denyRequest() {
      this.$refs.deny.changesNeeded = false;
      this.$refs.deny.dialog = true;
    },
    leaveTripRequest() {
      let to = '/trip-requests';
      if (this.redirectToDashboard) to = '/dashboard';
      else if (this.redirectToCalendar) to = '/calendars';
      else if (this.redirectToAssignments) to = '/assignments';
      this.eventHub.$emit('leaveTripRequestForm', {
        ok: this.tripRequest.status != 0 && this.tripRequest.status != -1,
        to,
      });
    },
    openTripEstimator() {
      this.$refs.tripEstimator.dialog = true;
    },
    async permissionSlip() {
      let inEnglish = this.hasPermissionSlipEnglishTemplate;
      if (this.hasPermissionSlipEnglishTemplate && this.hasPermissionSlipSpanishTemplate)
        inEnglish = await this.$myconfirm({
          message: 'Which language would you like to download the Permission Slip in?',
          yes: 'English',
          no: 'Spanish',
        });
      try {
        await this.downloadPermissionSlip({
          tripRequestId: this.tripRequest.id,
          uuid: randomString(16),
          reportId: 0,
          language: inEnglish ? 'english' : 'spanish',
        });
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    cancel() {
      this.$refs.cancel.dialog = true;
    },
    reschedule() {
      this.$refs.reschedule.dialog = true;
    },
    async createDuplicate() {
      try {
        const r = await this.duplicateTripRequest(this.tripRequest.id);
        if (r && r.id) {
          this.cardContent = 'tripRequest';
          this.$router.push(`/trip-request/${r.id}`);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  watch: {
    loading(newLoading) {
      if (!newLoading) {
        setTimeout(() => {
          window.print();
        }, 1500); // Delay of 3 seconds
      }
    },
    id() {
      this.refreshTripRequest();
    },
    e1(value) {
      switch (value) {
        case 1:
          this.eventHub.$emit('stepGeneralRequested');
          break;
        case 3:
          this.eventHub.$emit('stepDestinationRequested');
          break;
        case 6:
          this.eventHub.$emit('stepFundingSourceRequested');
          break;
        case 7:
          if (!this.showAdditionalInfo) this.eventHub.$emit('stepSupportingRequest');
          break;
        case 8:
          this.eventHub.$emit('stepSupportingRequest');
          break;
        default:
          break;
      }
    },
    tripRequest(value) {
      if (value.tripTypeId) this.checkAdditionalInfo();
    },
  },
};
</script>

<style scoped>
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.container {
  display: flex;
  /* max-height: calc(100vh - 84px - 94px - 12px); */
  width: 100%;
}
.form {
  height: 100%;
  width: 100%;
  min-height: 200px;
}
.nav-padding {
  padding-right: 56px;
}
.card-content {
  min-height: 270px;
  overflow-y: auto;
  max-height: calc(100vh - 84px - 94px - 94px);
}
.nav-buttons {
  margin: 0 6px;
}
.level-container {
  display: flex;
  justify-content: space-evenly;
}
.level {
  display: inline-block;
}
</style>
