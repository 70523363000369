<template>
  <v-dialog v-model="dialog" max-width="640px" no-click-animation persistent>
    <v-card>
      <v-tabs v-model="tab" grow color="primary">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#range">Date Range</v-tab>
        <v-tab href="#calendar">Select Dates</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="range">
          <v-card flat>
            <v-card-text>
              <v-row justify="center" class="pb-4">
                <v-col cols="12" md="4">
                  <date-picker label="Ending Date" v-model="endDate" :min="min"></date-picker>
                </v-col>

                <v-col cols="12">
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      :disabled="!endDate"
                      :key="i"
                      :label="d.label"
                      :value="d.value"
                      @change="handleDaySelected"
                      class="checkbox"
                      dense
                      v-for="(d, i) of dayItems"
                      v-model="days"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="2" value="calendar">
          <v-card flat>
            <v-card-text>
              <v-row justify="center" class="pb-4">
                <v-date-picker v-model="dates" class="mt-4" multiple :min="min"></v-date-picker>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" @click="accept()">Select Dates</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { eachDayOfInterval, addDays, getDay, format } from 'date-fns';
import { SAVE_TRIP_RECURRENCE } from '@/store/modules/TripRequest/actions';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'RecurringPicker',
  inject: ['eventHub'],
  components: { DatePicker },
  props: {
    recurrence: Array,
    tripRequest: Object,
    leaveDate: String,
  },
  data() {
    return {
      dialog: false,
      tab: 'range',
      dates: [],
      endDate: '',
      days: [],
      dayItems: [
        {
          label: 'Sun',
          value: 0,
        },
        {
          label: 'Mon',
          value: 1,
        },
        {
          label: 'Tues',
          value: 2,
        },
        {
          label: 'Wed',
          value: 3,
        },
        {
          label: 'Thurs',
          value: 4,
        },
        {
          label: 'Fri',
          value: 5,
        },
        {
          label: 'Sat',
          value: 6,
        },
      ],
      range: [],
    };
  },
  computed: {
    min() {
      return this.leaveDate
        ? format(addDays(new Date(this.leaveDate), 2), 'yyyy-MM-dd') // format subtracts a day for some reason?
        : format(new Date(), 'yyyy-MM-dd');
    },
  },
  methods: {
    ...mapActions('tripRequest', [SAVE_TRIP_RECURRENCE]),
    async accept() {
      try {
        const r = await this[SAVE_TRIP_RECURRENCE]({
          tripRequestId: this.tripRequest.id,
          dates: this.dates,
        });

        if (r && r.id && this.tripRequest.status != 0) {
          this.$emit('refresh');
        } else {
          this.$emit('setRecurrence', this.dates);
        }

        this.close();
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    getDaysRange() {
      this.range = eachDayOfInterval({
        start: addDays(new Date(this.leaveDate), 2),
        end: addDays(new Date(this.endDate), 1),
      });
    },
    handleDaySelected() {
      this.dates = this.range.filter((e) => this.days.includes(getDay(e))).map((e) => format(e, 'yyyy-MM-dd'));
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;

      this.endDate = '';
      this.days = [];
      this.range = [];
      this.dates = [...(this.recurrence || [])];
    },
  },
  watch: {
    endDate(value) {
      if (value) this.getDaysRange();
    },
    recurrence(value) {
      if (value?.length) this.dates = [...value];
    },
  },
};
</script>

<style lang="scss">
.checkbox-wrapper {
  display: flex;
  align-items: center !important;
  flex-wrap: wrap;
  padding: 12px;
}
.checkbox {
  margin-right: 20px;
}
</style>
