<template>
  <v-dialog v-model="dialog" max-width="700px" no-click-animation>
    <v-card>
      <v-card-title>{{ dir }} Directions</v-card-title>
      <v-card-text class="pt-5">
        <v-row dense>
          <v-col cols="12" md="2"><div class="font-weight-bold">Origin</div></v-col>
          <v-col cols="auto">{{ origin }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="2"><div class="font-weight-bold">Destination</div></v-col>
          <v-col cols="auto">{{ destination }} </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="2"><div class="font-weight-bold">Distance</div></v-col>
          <v-col cols="auto">{{ distance }} miles</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="2"><div class="font-weight-bold">Navigation</div></v-col>
          <v-col cols="12" md="10">
            <v-row dense v-for="(d, i) of directions" :key="i">
              <v-col cols="auto">
                <v-icon>{{ dirIcons[d.image] || '' }}</v-icon>
              </v-col>
              <v-col cols="auto">{{ getText(d) }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="isDownloadOngoing" @click="printDirections"> Print </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMilesForDirections } from '@/util';
import { downloadAttachmentMixin } from '@/util/downloadAttachment';
import Geo from '@/apis/geo';
export default {
  name: 'Directions',
  inject: ['eventHub'],
  components: {},
  props: {
    originId: Number,
    destinationId: [Number, String],
    waypoints: Array,
  },
  data() {
    return {
      getMilesForDirections,
      dialog: false,
      dir: '',
      directions: [],
      distance: 0,
      dirIcons: {
        // TODO: ICONS NEED TO BE REVIEWED
        'turn.left': 'mdi-arrow-left-top-bold',
        'turn.right': 'mdi-arrow-right-top-bold',
        'u-turn': 'mdi-arrow-u-down-left-bold',
        continue: 'mdi-arrow-up-thick',
        arrive: 'mdi-map-marker-check',
        'arrive.left': 'mdi-map-marker-check',
        'arrive.right': 'mdi-map-marker-check',
        'turn.slightright': 'mdi-arrow-right-top-bold',
        'turn.slightleft': 'mdi-arrow-left-top-bold',
        'keep.right': 'mdi-arrow-right-top-bold',
        'keep.left': 'mdi-arrow-left-top-bold',
        'sharp.right': 'mdi-arrow-right-top-bold',
        'sharp.left': 'mdi-arrow-left-top-bold',
        uturn: 'mdi-arrow-u-down-right-bold',
        'turn.sharpright': 'mdi-arrow-right-top-bold',
        'turn.sharpleft': 'mdi-arrow-left-top-bold',
        roundabout: 'mdi-autorenew',
      },
    };
  },
  computed: {
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinationsById']),
    origin() {
      if (this.originId) return this.locationsById[this.originId].name;
      return '';
    },
    destination() {
      if (this.destinationId && this.destinationId > 0) return this.destinationsById[this.destinationId].name;
      return '';
    },
  },
  methods: {
    findWaypoint(waypointName) {
      for (let i = 0; i < this.waypoints.length; i++) {
        if (Object.prototype.hasOwnProperty.call(this.waypoints[i], waypointName)) {
          return `Arrive at ${this.waypoints[i][waypointName]}`;
        }
      }
      return null;
    },
    getText(instruction) {
      let t = instruction.text;
      if (instruction.distance) t += ', go ' + getMilesForDirections(instruction.distance);
      return this.findWaypoint(t) || t;
    },
    async printDirections() {
      try {
        const callback = async () => {
          return Geo.printDirections({
            directions: this.directions,
            origin: this.origin,
            destination: this.destination,
            distance: this.distance,
            dir: this.dir,
          });
        };
        this.downloadAttachment(callback, 'directions2.pdf');
      } catch (error) {
        console.log(error);
      }
    },
  },
  mixins: [downloadAttachmentMixin],
};
</script>

<style lang="scss"></style>
