<template>
  <div>
    <v-container fluid>
      <v-form class="full-width" ref="form2" :readonly="readonly">
        <div>
          <v-row>
            <v-col cols="12" md="4">
              <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                <small>*Required</small>
              </span>

              <date-picker
                :min="minDate"
                :readonly="readonly && !canEditDates"
                :rules="[() => !!tripRequest.leaveDate || 'This field is required']"
                @input="setOtherDates($event, 'leaveDate')"
                label="Leave Date"
                ref="leaveDate"
                required
                v-model="tripRequest.leaveDate"
              ></date-picker>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" md="4">
              <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                <small>*Required</small>
              </span>

              <time-picker
                :readonly="readonly && !canEditDates"
                :rules="[() => !!tripRequest.leaveTime || 'This field is required']"
                @input="setOtherDates($event, 'leaveTime')"
                label="Leave Time"
                ref="leaveTime"
                required
                v-model="tripRequest.leaveTime"
              ></time-picker>
            </v-col>

            <v-spacer></v-spacer>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                <small>*Required</small>
              </span>

              <date-picker
                :min="minDate"
                :readonly="readonly && !canEditDates"
                :rules="[() => !!tripRequest.returnDate || 'This field is required']"
                @change="setOtherDates($event, 'returnDate')"
                label="Return Date"
                ref="returnDate"
                required
                v-model="tripRequest.returnDate"
              ></date-picker>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" md="4">
              <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                <small>*Required</small>
              </span>

              <time-picker
                :readonly="readonly && !canEditDates"
                :rules="[() => !!tripRequest.returnTime || 'This field is required']"
                @input="setOtherDates($event, 'returnTime')"
                label="Return Time"
                ref="returnTime"
                required
                v-model="tripRequest.returnTime"
              ></time-picker>
            </v-col>

            <v-spacer></v-spacer>
          </v-row>

          <template v-if="tripRequest.status == -1 || tripRequest.status == 0">
            <v-row v-for="am in [...activeMessages]" :key="am.id" dense>
              <v-col cols="12" md="12">
                <v-alert outlined :type="am.alertType || 'info'" text>{{ am.message }}</v-alert>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="12" md="4">
              <span
                v-if="
                  (!tripRequest.status || tripRequest.status == -1) && tripRequestConfig.display.overnightOOSRequired
                "
                class="red--text"
              >
                <small>*Required</small>
              </span>

              <v-radio-group
                v-model.number="tripRequest.outOfState"
                @change="handleOOSChange"
                :readonly="readonly"
                class="mt-0"
              >
                <template v-slot:label>
                  <div>{{ tripRequestConfig.labels.overnightOOS }}</div>
                </template>

                <v-radio :value="1">
                  <template v-slot:label>
                    <div><strong class="success--text">Yes</strong></div>
                  </template>
                </v-radio>

                <v-radio :value="0">
                  <template v-slot:label>
                    <div><strong class="primary--text">No</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              md="4"
              v-if="
                tripRequest.outOfState ||
                (tripRequestConfig.display.outOfCounty &&
                  tripRequestConfig.display.outOfCountyTypes.includes(tripRequest.tripTypeId))
              "
            >
              <span
                v-if="
                  (!tripRequest.status || tripRequest.status == -1) && tripRequestConfig.display.overnightOOSRequired
                "
                class="white--text"
              >
                <small>*</small>
              </span>

              <v-radio-group v-model.number="tripRequest.outOfCounty" :readonly="readonly" class="mt-0">
                <template v-slot:label>
                  <div>{{ tripRequestConfig.labels.outOfCounty }}</div>
                </template>

                <v-radio :value="1">
                  <template v-slot:label>
                    <div><strong class="success--text">Yes</strong></div>
                  </template>
                </v-radio>

                <v-radio :value="0">
                  <template v-slot:label>
                    <div><strong class="primary--text">No</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="4" v-if="tripRequestConfig.display.actualTimeTBD">
              <v-radio-group v-model.number="tripRequest.actualTimeTBD" :readonly="readonly" class="mt-0">
                <template v-slot:label>
                  <div>Actual Time TBD</div>
                </template>

                <v-radio :value="1">
                  <template v-slot:label>
                    <div><strong class="success--text">Yes</strong></div>
                  </template>
                </v-radio>

                <v-radio :value="0">
                  <template v-slot:label>
                    <div><strong class="primary--text">No</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="customFormFields">
            <custom-form-field
              v-for="(cff, i) in customFormFields"
              :ref="cff.id"
              :key="i"
              :cff="cff"
              :value="tripRequest.customFormFields[cff.id]"
              :readonly="readonly"
              @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
            ></custom-form-field>
          </v-row>

          <v-row
            v-if="
              tripRequestConfig.display.recurringTrips ||
              me.is.superAdmin ||
              me.is.transportationAdmin ||
              me.is.limitedAdmin
            "
          >
            <v-col cols="12" md="4">
              <v-btn color="primary" @click="openRecurringTrip" :disabled="disableRecurring">
                {{ tripRequest.recurrence && tripRequest.recurrence.length > 0 ? 'Edit' : 'Create' }} Recurring Trips
              </v-btn>

              <p>{{ tripRequestConfig.messages.recurringButton }}</p>
            </v-col>
          </v-row>

          <v-row dense v-if="tripRequest.recurrence && tripRequest.recurrence.length">
            <v-col cols="12" md="12">
              <v-alert outlined type="info" text>
                Dates selected:
                {{ tripRequest.recurrence.map((e) => readableDate(e)).join(', ') }}
              </v-alert>
            </v-col>

            <v-col cols="12" md="12" v-if="tripRequest.status == 0">
              <v-alert outlined type="warning" text>
                Recurring trips are not created until this trip request is approved
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-container>

    <recurring-picker
      :leaveDate="tripRequest.leaveDate"
      :recurrence="tripRequest.recurrence"
      :tripRequest="tripRequest"
      @refresh="$emit('refresh')"
      @save="$emit('save')"
      @setRecurrence="setRecurrence"
      ref="rpicker"
    ></recurring-picker>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, GET_TRIP_DATE_TIMES } from '@/store/modules/Config/actions';
import { uniq } from 'lodash';
import { differenceInDays, format } from 'date-fns';
import { readableDate } from '@/util';
import RecurringPicker from './RecurringPicker.vue';
import CustomFormField from './CustomFormField.vue';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import { TRIP_STATUS } from '@/shared/common';

export default {
  name: 'TravelLeaveReturn',
  inject: ['eventHub'],
  components: { RecurringPicker, TimePicker, DatePicker, CustomFormField },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    step: Number,
    complete: Boolean,
    customFormFields: Array,
    readonly: Boolean,
  },
  data() {
    return {
      readableDate,
      activeMessages: new Set(),
      leadDays: {},
      cannotSubmit: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['blockedDates', 'specialDates', 'tripTimeMessages']),
    ...mapGetters('semester', ['semesters']),
    ...mapGetters('tripRequest', ['isCurrentTripRequestApproved']),
    disableRecurring() {
      let d =
        this.cannotSubmit ||
        !this.tripRequest.leaveDate ||
        !this.tripRequest.returnDate ||
        this.tripRequest.leaveDate != this.tripRequest.returnDate ||
        this.tripRequest.batchId > 0;

      if (
        this.tripRequest.approval.awaitingApproval &&
        this.tripRequest.approval.requiredApprovalLevels.findIndex(
          (e) => e.id == this.tripRequest.approval.awaitingApproval.id
        ) > -1
      ) {
        d = true;
      }

      return d;
    },
    minDate() {
      return [TRIP_STATUS.DENIED, TRIP_STATUS.RESUBMIT, TRIP_STATUS.DRAFT].indexOf(this.tripRequest.status) > -1
        ? format(new Date(), 'yyyy-MM-dd')
        : undefined;
    },
    isOvernightStay() {
      const leaveDate = new Date(this.tripRequest.leaveDate);
      const returnDate = new Date(this.tripRequest.returnDate);
      return returnDate > leaveDate;
    },
    canEditDates() {
      if (!this.isCurrentTripRequestApproved && this.tripRequest.submittedUser === this.me.id) return true;
      if (!this.tripRequest.assignments) return true;
      return this.tripRequest.permissions.canEditApproved && !this.tripRequest.assignmentsComplete;
    },
  },
  created() {
    this.eventHub.$on('validateStepLeaveReturn', (reset) => this.validate(reset));
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepLeaveReturn');
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, GET_TRIP_DATE_TIMES]),

    async fetchItems() {
      this.leadDays = await this.getConfig('leadDays');
      if (this.tripRequest.leaveDate || this.tripRequest.returnDate)
        this.checkTripDates([this.tripRequest.leaveDate, this.tripRequest.returnDate]);
    },
    handleOOSChange() {
      if (this.tripRequest.tripTypeId) this.$emit('tripTypeSelected', this.tripRequest.tripTypeId);
    },
    openRecurringTrip() {
      this.$refs.rpicker.dialog = true;
    },
    setRecurrence(dates) {
      this.tripRequest.recurrence = dates;
    },
    canOverrideDatePrevention() {
      return (
        this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin || this.me.is.vehicleOwner
      );
    },
    checkTripTimes(times) {
      if (this.tripRequest.tripTypeId) {
        times = times.filter((e) => e);
        for (let time of times)
          for (let ttm of this.tripTimeMessages)
            if (
              ttm.tripTypeIds &&
              ttm.tripTypeIds.includes(this.tripRequest.tripTypeId) &&
              ttm.begin <= time &&
              time <= ttm.end
            ) {
              ttm.message = ttm.description;
              this.activeMessages.add(ttm);
            }
      }
    },
    checkIfTripIsOutOfSchoolYear(tripRequest) {
      const currentSemester = this.semesters.find((semester) => semester.id === this.me.semesterId);
      const leaveDate = new Date(tripRequest.leaveDate);
      const semesterEndDate = new Date(currentSemester.toDate);
      const semesterStartDate = new Date(currentSemester.fromDate);
      if (tripRequest.leaveDate && (leaveDate < semesterStartDate || leaveDate > semesterEndDate)) {
        this.activeMessages.add({
          message: 'Trip falls outside the current school year',
          alertType: 'info',
        });
      }
    },
    checkTripDates(dates) {
      if (this.tripRequest.leaveDate && !this.tripRequest.returnDate)
        this.tripRequest.returnDate = this.tripRequest.leaveDate;

      let prevent = false;
      if (this.tripRequest.leaveDate && this.tripRequest.returnDate) {
        // only set automatically for overnight
        if (this.isOvernightStay) {
          this.tripRequest.outOfState = 1;
        }

        if (this.tripRequest.returnDate < this.tripRequest.leaveDate) {
          this.activeMessages.add({
            message: 'Return date cannot be before leave date',
            alertType: 'error',
          });
          prevent = true;
        } else if (
          this.tripRequest.returnDate == this.tripRequest.leaveDate &&
          this.tripRequest.returnTime &&
          this.tripRequest.leaveTime.localeCompare(this.tripRequest.returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) > 0
        ) {
          this.activeMessages.add({
            message: 'Return time cannot be before leave time',
            alertType: 'error',
          });
          prevent = true;
        } else if (
          this.tripRequest.returnDate == this.tripRequest.leaveDate &&
          this.tripRequest.returnTime &&
          this.tripRequest.leaveTime.localeCompare(this.tripRequest.returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) == 0
        ) {
          this.activeMessages.add({
            message: 'Return date time cannot be the same as leave date time',
            alertType: 'error',
          });
          prevent = true;
        }
      }

      if (this.tripRequest.tripTypeId) {
        dates = uniq(dates.filter((e) => e));
        const leadDaysConfig = this.tripRequest.outOfState ? this.leadDays.oos : this.leadDays.general;
        const leadDaysTripTypeMatched =
          !leadDaysConfig.tripTypeIds.length || leadDaysConfig.tripTypeIds.includes(this.tripRequest.tripTypeId);
        const leadDaysTripEventMatched =
          !leadDaysConfig.tripEventIds.length ||
          this.tripRequest.tripEventIds.some((tripEventId) => leadDaysConfig.tripEventIds.includes(tripEventId));

        for (let date of dates) {
          const activeMessagesArray = [...this.activeMessages];
          if (
            leadDaysTripTypeMatched &&
            leadDaysTripEventMatched &&
            differenceInDays(new Date(date), new Date()) < leadDaysConfig.numDays &&
            !activeMessagesArray.map((e) => e.id).includes('lead')
          ) {
            const message = {
              id: 'lead',
              message: `Lead time is ${leadDaysConfig.numDays} days. ${
                leadDaysConfig.type == 'prevent' ? this.leadDays.preventSubmitMessage : this.leadDays.warningMessage
              }`,
              alertType: leadDaysConfig.type == 'prevent' ? 'error' : 'info',
            };

            if (leadDaysConfig.type == 'prevent' && !this.canOverrideDatePrevention()) {
              message.alertType = 'error';
              prevent = true;
            } else {
              message.alertType = 'info';
            }
            this.activeMessages.add(message);
          }

          for (let bd of this.blockedDates)
            if (
              bd.tripTypeIds.includes(this.tripRequest.tripTypeId) &&
              bd.tripEventIds.some((e) => this.tripRequest.tripEventIds.includes(e)) &&
              bd.begin <= date &&
              date <= bd.end
            ) {
              bd.message = 'Trip date is blocked by District Office: ' + bd.description;
              const isAdmin = this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
              if (isAdmin) bd.message += ' (As an admin you can still submit this trip request)';
              bd.alertType = isAdmin ? 'info' : 'error';
              this.activeMessages.add(bd);
              prevent |= !isAdmin;
            }
          for (let sd of this.specialDates)
            if (sd.tripTypeIds.includes(this.tripRequest.tripTypeId) && sd.begin <= date && date <= sd.end) {
              sd.message = 'Reminder: ' + sd.description;
              this.activeMessages.add(sd);
            }
        }
        this.$nextTick(() => {
          this.$emit('preventSubmit', prevent && this.tripRequest.status == 0);
        });
      }
      this.cannotSubmit = prevent && this.tripRequest.status == 0;
    },
    setOtherDates(date, field) {
      if (field == 'leaveDate') {
        this.tripRequest.returnDate = date;
        this.tripRequest.vehPickupDate = date;
        this.tripRequest.vehReturnDate = date;
      }
      if (field == 'leaveTime') {
        this.tripRequest.vehPickupTime = date;
      } else if (field == 'returnDate') {
        this.tripRequest.vehReturnDate = date;
      } else if (field == 'returnTime') {
        this.tripRequest.vehReturnTime = date;
      }
    },
    validate(reset) {
      const required = ['leaveDate', 'returnDate', 'leaveTime', 'returnTime'];
      if (!reset) {
        required.forEach((e) => this.$refs[e].validate(true));
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        required.forEach((e) => this.$refs[e].resetValidation());
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
  },
  watch: {
    tripRequest: {
      immediate: true,
      deep: true,
      handler: function (tripRequest) {
        this.activeMessages = new Set();
        if (tripRequest.leaveTime || tripRequest.returnTime)
          this.checkTripTimes([tripRequest.leaveTime, tripRequest.returnTime]);
        if (this.step == 2 && (tripRequest.leaveDate || tripRequest.returnDate)) {
          this.checkTripDates([tripRequest.leaveDate, tripRequest.returnDate]);
          this.checkIfTripIsOutOfSchoolYear(tripRequest);
        }
      },
    },
    step(value) {
      if (value == 2 && this.tripRequest.status == 0 && !this.complete) {
        this.tripRequest.outOfState = null;
        this.tripRequest.actualTimeTBD = null;
      }
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
