<template>
  <div class="pa-2">
    <section class="mb-4">
      <div v-if="!tripRequest.needDistrictVehicles" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip does not require District Vehicles</h2>
      </div>

      <div v-else-if="awaitingApproval" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-alert-circle </v-icon>
        <h2>This trip requires approval first</h2>
      </div>

      <div v-else-if="!assignmentsNeeded && !driversNeeded && !vehiclesNeeded" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip has a vehicle & driver assigned</h2>
      </div>

      <div v-else class="d-flex">
        <v-icon class="mr-2" color="blue"> mdi-alert-circle </v-icon>
        <h2>This trip requires vehicle/driver assignment</h2>
      </div>
    </section>

    <v-row>
      <v-col cols="12" md="12">
        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <section v-if="tripRequest.needDistrictVehicles && !awaitingApproval">
      <v-row>
        <v-col cols="12" md="12">
          <v-card
            outlined
            v-if="tripIsPickupDropoffOnly['Pickup'] || tripIsPickupDropoffOnly['Dropoff']"
            class="amber--text darken-1"
          >
            <v-alert outlined color="amber darken-2" class="pa-0 ma-0">
              <v-card-title
                ><v-icon color="warning" class="m-r-2">mdi-map-marker-alert-outline</v-icon>This trip is{{
                  tripIsPickupDropoffOnly.Dropoff ? ' dropoff ' : ' pickup '
                }}
                only.
              </v-card-title>
            </v-alert>
          </v-card>

          <v-card outlined>
            <v-card-title>Reserve Vehicles</v-card-title>

            <v-banner color="red lighten-4" v-if="!vehicleLocations.length">
              The chosen location for this trip -- <strong>{{ tripLocationName }}</strong> -- does not have Vehicle
              Order of Assignments set up, and therefore you cannot reserve a vehicle location. Please reach out to your
              transportation admin.
            </v-banner>

            <v-card-text v-else>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :disabled="!canUseVehicleLocationDropdown"
                    :hint="reserveFromLocationVehicleOwner"
                    :item-text="getLocationText"
                    :items="vehicleLocations"
                    :menu-props="{ bottom: true, offsetY: true }"
                    @change="handleLocationChange"
                    item-value="id"
                    label="Location Used to Reserve Vehicles"
                    outlined
                    persistent-hint
                    v-model="reserveFromLocation"
                  ></v-select>
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    :disabled="!canAssign || isLastVehicleLocation"
                    :loading="updatingReserveLocation"
                    @click="nextLocation(false)"
                    class="mt-2"
                    color="primary"
                  >
                    Next Location
                  </v-btn>
                </v-col>

                <v-col cols="auto" v-show="canUseVehicleLocationDropdown">
                  <v-btn
                    :disabled="!canAssign || !reserveLocationChanged"
                    :loading="savingReserveLocation"
                    @click="nextLocation(true)"
                    class="mt-2"
                    color="success"
                  >
                    Save Reserve Location
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-bold">Trip Location Zone</div>
                  <div class="text-subtitle-2 font-weight-bold">Bid ID</div>
                  <div class="text-subtitle-2 font-weight-bold">
                    # Vehicles Needed {{ tripRequest.splitTrip ? '(Per Leg)' : '' }}
                  </div>
                  <div class="text-subtitle-2 font-weight-bold" v-if="tripRequest.splitTrip">Split Trip</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-normal">{{ tripZone }}</div>
                  <div class="text-subtitle-2 font-weight-normal">xx-xx-xx</div>
                  <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numVehicles }}</div>
                  <div class="text-subtitle-2 font-weight-normal" v-if="tripRequest.splitTrip">Yes</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-bold">Trip Year/Week</div>
                  <div class="text-subtitle-2 font-weight-bold">Trip Hours</div>
                  <div class="text-subtitle-2 font-weight-bold">Number w/ Lift</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-normal">YYYY-MM</div>
                  <div class="text-subtitle-2 font-weight-normal">
                    {{ tripHours }} <span v-if="tripRequest.actualTimeTBD" class="red--text">*Actual Time TBD</span>
                  </div>
                  <div class="text-subtitle-2 font-weight-normal">0</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="tripRequest.splitTrip && tripRequest.assignments.length === 0 && !showVehicleAndDriverAssignmentButtons"
      >
        <v-col cols="12" md="6">
          <v-btn
            color="primary"
            class="mx-2"
            @click="showVehicleAndDriverAssignment()"
            :disabled="!canAssign || disableAssignments"
          >
            Click to Show Vehicle and Driver assignment buttons
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else-if="tripRequest.splitTrip">
        <v-col cols="12" md="6" v-if="doLegAssignmentsNeeded">
          <div class="subtitle-2 leg-label">Drop-Off Leg:</div>

          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAV(0, 1)"
              :disabled="!canAssign || disableAssignments || !reserveFromLocation"
            >
              {{ favText }}
            </v-btn>

            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAD(0, 1)"
              :disabled="!canAssign || disableAssignments"
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>

        <v-col cols="12" md="6" v-if="rLegAssignmentsNeeded">
          <div class="subtitle-2 leg-label">Return Leg:</div>

          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAV(0, 2)"
              :disabled="!canAssign || disableAssignments || !reserveFromLocation"
            >
              {{ favText }}
            </v-btn>

            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAD(0, 2)"
              :disabled="!canAssign || disableAssignments"
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>
      </v-row>

      <v-row v-else-if="tripRequest.vehicleType !== VEHICLE_TYPES.NORMAL && tripRequest.additionalTransportationId">
        <v-col cols="12" md="6" v-if="assignmentsNeeded">
          <v-btn
            color="primary"
            @click="addAgencyAssignments()"
            :disabled="
              isLoading ||
              !canAssign ||
              disableAssignments ||
              (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled)
            "
          >
            Fulfill Assignments From {{ vehicleTypesById[tripRequest.vehicleTypeId].name }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" md="6">
          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              @click="openFAV(0)"
              :disabled="
                !canAssign ||
                disableAssignments ||
                (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled) ||
                !reserveFromLocation
              "
            >
              {{ favText }}
            </v-btn>
          </template>
        </v-col>

        <v-col cols="12" md="6" v-if="driverConfig.useAvailableDrivers">
          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              @click="openFAD(0)"
              :disabled="
                !canAssign ||
                disableAssignments ||
                (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled) ||
                (!tripRequest.needDriverAssigned && !driverConfig.useAvailableDrivers)
              "
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>

        <v-col v-else-if="!tripRequest.assignments.length || tripRequest.assignments.length < tripRequest.numVehicles">
          <v-row>
            <v-col cols="9">
              <v-row>
                <v-col cols="12" md="6">
                  <v-combobox
                    v-model="driver.details"
                    :items="driverItems"
                    :item-text="getDriverText"
                    label="Driver"
                    return-object
                    v-bind="inputProps"
                    @keydown="driver.details = null"
                    @change="driver.email = driver.details?.email || driver.email"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="driver.email" v-bind="inputProps" label="Driver Email" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="d-flex align-center justify-center">
              <v-btn block color="primary" :disabled="isReserveDriverDisabled" @click="reserveDriver">
                Reserve Driver
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" v-for="(assignment, i) of tripRequest.assignments" :key="i">
          <v-card outlined :loading="isLoadingFAD">
            <v-card-title>
              Assignment #{{ i + 1 }}
              <v-icon v-if="assignment.leg > 0" class="ml-4" :color="assignment.leg == 1 ? 'green' : 'blue'">
                {{ assignment.leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}
              </v-icon>

              <v-spacer></v-spacer>

              <trip-ticket-download-button v-if="hasTripTicketTemplate" :assignmentIds="[assignment.id]" />

              <v-btn
                text
                color="error"
                @click="removeAssignment(assignment.id)"
                v-if="
                  me.is.superAdmin || me.is.transportationAdmin || me.is.vehicleOwner || me.is.specialNeedsVehicleOwner
                "
                :loading="isLoadingFAD"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" :md="assignment.assistantId ? '4' : '6'">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="40px"></th>
                          <th class="text-left">Vehicle</th>
                          <th class="text-left">Vehicle Location</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="assignment.vehicleId || assignment.vehicle">
                          <td>
                            <v-btn
                              icon
                              color="error"
                              @click="removeVehicle(assignment)"
                              v-if="
                                tripRequest.vehicleType === VEHICLE_TYPES.NORMAL &&
                                (me.is.superAdmin ||
                                  me.is.transportationAdmin ||
                                  me.is.vehicleOwner ||
                                  me.is.specialNeedsVehicleOwner)
                              "
                              :disabled="isLoading"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>

                          <td>{{ getVehicle(assignment) }}</td>
                          <td>{{ getVehicleLocation(assignment.vehicleId) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col cols="12" :md="assignment.assistantId ? '4' : '6'">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="40px"></th>
                          <th class="text-left">Driver</th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="
                          driverConfig.useAvailableDrivers &&
                          tripRequest.needDriverAssigned &&
                          (assignment.driver || assignment.driverId)
                        "
                      >
                        <tr>
                          <td>
                            <v-btn
                              icon
                              color="error"
                              @click="removeDriver(assignment)"
                              v-if="
                                tripRequest.vehicleType === VEHICLE_TYPES.NORMAL &&
                                (me.is.superAdmin ||
                                  me.is.transportationAdmin ||
                                  me.is.vehicleOwner ||
                                  me.is.specialNeedsVehicleOwner)
                              "
                              :disabled="isLoading"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>

                          <td v-if="!assignment.driverId">{{ assignment.driver }}</td>
                          <td v-else>
                            {{ driversById[assignment.driverId].firstName }}
                            {{ driversById[assignment.driverId].lastName }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-row v-if="!driverConfig.useAvailableDrivers || !tripRequest.needDriverAssigned" class="mt-2">
                    <v-col cols="12" md="1">
                      <v-btn
                        icon
                        class="mt-3"
                        color="error"
                        @click="removeDriver(assignment)"
                        v-if="showDriverDeleteButton({ assignment })"
                        :disabled="isLoading"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>

                    <template v-if="!driverConfig.useAvailableDrivers">
                      <v-col cols="10" md="5">
                        <v-combobox
                          v-model="assignment.driver"
                          :items="driverItems"
                          :item-text="getDriverText"
                          label="Driver"
                          return-object
                          outlined
                          @change="setDriverEmail(assignment)"
                          :disabled="
                            !schoolFinanceDriverEditable || tripRequest.vehicleType === VEHICLE_TYPES.APPROVED_CHARTER
                          "
                        />
                      </v-col>

                      <v-col cols="10" md="5">
                        <v-text-field
                          v-model="assignment.driverEmail"
                          label="Driver Email"
                          outlined
                          :disabled="
                            !schoolFinanceDriverEditable || tripRequest.vehicleType === VEHICLE_TYPES.APPROVED_CHARTER
                          "
                        />
                      </v-col>

                      <v-col cols="auto">
                        <v-btn
                          text
                          color="success"
                          class="mt-3"
                          :loading="isLoading"
                          @click="reserve([assignment])"
                          icon
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </v-col>
                    </template>

                    <template v-else>
                      <v-col>
                        {{ assignment.driver || getDriverText(driversById[assignment.driverId]) }}
                      </v-col>

                      <v-col>
                        {{ assignment.driverEmail || driversById[assignment.driverId]?.email }}
                      </v-col>
                    </template>
                  </v-row>

                  <v-row v-if="tripRequest.vehicleType !== VEHICLE_TYPES.NORMAL" class="mt-2">
                    <v-col cols="12" md="1">
                      <v-btn
                        icon
                        class="mt-3"
                        color="error"
                        @click="removeMechanic(assignment)"
                        v-if="
                          (me.is.superAdmin ||
                            me.is.transportationAdmin ||
                            me.is.vehicleOwner ||
                            me.is.specialNeedsVehicleOwner) &&
                          (assignment.mechanic || assignment.mechanicEmail)
                        "
                        :disabled="isLoading"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12" md="5" v-if="mechanicItems">
                      <v-combobox
                        v-model="assignment.mechanic"
                        :value="assignment.mechanic"
                        :items="mechanicItems"
                        :item-text="getMechanicText"
                        label="Mechanic"
                        return-object
                        outlined
                        @change="handleMechanicAdded($event, assignment)"
                        @update:search-input="handleMechanicAdded($event, assignment)"
                      ></v-combobox>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="assignment.mechanicEmail"
                        :value="assignment.mechanicEmail"
                        label="Mechanic Email"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="1">
                      <v-btn
                        icon
                        class="mt-3"
                        color="success"
                        @click="saveAssignmentMechanic([assignment])"
                        :disabled="isLoading"
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4" v-if="assignment.assistantId">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="40px"></th>
                          <th class="text-left">Assistant</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <v-btn
                              icon
                              color="error"
                              @click="removeAssistant(assignment)"
                              v-if="
                                me.is.superAdmin ||
                                me.is.transportationAdmin ||
                                me.is.vehicleOwner ||
                                me.is.specialNeedsVehicleOwner
                              "
                              :disabled="isLoading"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>

                          <td>
                            {{ driversById[assignment.assistantId].firstName }}
                            {{ driversById[assignment.assistantId].lastName }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row v-if="tripRequest.vehicleType === VEHICLE_TYPES.NORMAL">
                <v-col cols="12" :md="assignment.assistantId ? '4' : '6'">
                  <v-btn
                    v-if="!assignment.vehicleId"
                    color="primary"
                    @click="openFAV(assignment.id, assignment.leg)"
                    :disabled="!canAssign"
                  >
                    {{ favText }}
                  </v-btn>

                  <v-btn
                    v-else
                    color="primary"
                    @click="openFAV(assignment.id, assignment.leg)"
                    :disabled="!canAssign"
                    :loading="isLoadingVehicle"
                  >
                    Change Vehicle
                    <template v-slot:loader>
                      <v-progress-circular indeterminate></v-progress-circular>
                    </template>
                  </v-btn>
                </v-col>

                <v-col cols="12" :md="assignment.assistantId ? '4' : '6'" v-if="driverConfig.useAvailableDrivers">
                  <v-btn
                    v-if="!assignment.driverId && !assignment.driver"
                    color="primary"
                    @click="openFAD(assignment.id)"
                    :disabled="!canAssign"
                  >
                    {{ fadText }}
                  </v-btn>

                  <template v-else-if="tripRequest.needDriverAssigned">
                    <v-btn color="primary" @click="openFAD(assignment.id)" :disabled="!canAssign" :loading="isLoading">
                      Change Driver
                      <template v-slot:loader>
                        <v-progress-circular indeterminate></v-progress-circular>
                      </template>
                    </v-btn>

                    <!-- <v-btn color="primary" class="ml-4" @click="declineDriver" :disabled="!canAssign">
                      Decline Driver
                    </v-btn> -->
                  </template>

                  <template v-else-if="!tripRequest.needDriverAssigned">
                    <v-btn color="primary" @click="openFAD(assignment.id)" :disabled="!canAssign" :loading="isLoading">
                      Change Driver
                      <template v-slot:loader>
                        <v-progress-circular indeterminate></v-progress-circular>
                      </template>
                    </v-btn>
                  </template>

                  <v-btn
                    v-if="assignment.driverId && !assignment.assistantId && (isAdmin || me.is.vehicleOwner)"
                    class="ml-4"
                    color="primary"
                    @click="openFAD(assignment.id, 0, true)"
                    :disabled="!canAssign"
                  >
                    Add Assistant
                  </v-btn>
                </v-col>

                <v-col cols="12" md="4" v-if="assignment.assistantId">
                  <v-btn color="primary" @click="openFAD(assignment.id, 0, true)" :disabled="!canAssign">
                    Change Assistant
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <find-available-vehicles
      ref="fav"
      :tripRequest="tripRequest"
      :tripRequestConfig="tripRequestConfig"
      :voConfig="permissions.vehicleOwner"
      :reserveFromLocation="reserveFromLocation"
      :max="maxAssignments"
      :reserved="tripRequest.assignments.length"
      :reserving="reserving"
      @reserve="reserve"
    ></find-available-vehicles>

    <find-available-drivers
      ref="fad"
      :tripRequest="tripRequest"
      :tripRequestConfig="tripRequestConfig"
      :driverConfig="driverConfig"
      :reserveFromLocation="reserveFromLocation"
      :max="maxAssignments"
      :reserved="tripRequest.assignments.length"
      :reserving="reserving"
      @reserve="reserve"
    ></find-available-drivers>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format, differenceInMinutes } from 'date-fns';

import { SAVE_ASSIGNMENT, DELETE_ASSIGNMENT } from '@/store/modules/Assignment/actions';
import { UPDATE_ASSIGNMENT_LOCATION } from '@/store/modules/TripRequest/actions';
import { GET_MECHANICS, SAVE_MECHANIC } from '@/store/modules/Mechanic/actions';

import FindAvailableVehicles from '@/components/FindAvailableVehicles.vue';
import FindAvailableDrivers from '@/components/FindAvailableDrivers.vue';
import TripTicketDownloadButton from '@/components/TripTicketDownloadButton.vue';

import VEHICLE_TYPES from '@/shared/types';

import { getVehicleOwner, getVehicleLocation, inputProps } from '@/util';
import { validateSplitTripDates } from '@/util/tripRequest';
import { GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS } from '@/store/modules/Location/actions';

const FAV_TEXT = 'Find Available Vehicles';
const FAD_TEXT = 'Find Available Drivers';

export default {
  name: 'TripRequestAssignments',
  inject: ['eventHub'],
  mixins: [],
  components: { FindAvailableVehicles, FindAvailableDrivers, TripTicketDownloadButton },
  props: {
    assignments: Array,
    tripRequest: Object,
    tripRequestConfig: Object,
  },
  data() {
    return {
      assignment: {},
      disableAssignments: false,
      driver: { email: null, details: null },
      fadText: FAD_TEXT,
      favText: FAV_TEXT,
      format,
      getVehicleLocation,
      inputProps,
      isLoading: false, // Track loading state
      isLoadingFAD: false,
      isLoadingVehicle: false, // Track loading state
      mechanicItems: [],
      reserveFromLocation: 0,
      reserveFromLocationVehicleOwner: '',
      reserveLocationChanged: false,
      reserving: false,
      savingReserveLocation: false,
      showVehicleAndDriverAssignmentButtons: false,
      updatingReserveLocation: false,
      VEHICLE_TYPES,
      vehicleLocations: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('app', ['hasTripTicketTemplate']),
    ...mapGetters('config', ['driverConfig', 'vehicleOwnerConfig', 'permissions']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('location', { stateVehicleLocations: 'vehicleLocations' }),
    ...mapGetters('mechanic', ['mechanicsList']),
    ...mapGetters('stop', ['stopsById']),
    ...mapGetters('tripRequest', ['tripRequestsById']),
    ...mapGetters('user', ['me', 'vehicleOwners']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    canAssign() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.schoolFinanceDriverEditable ||
        this.tripRequest.permissions.canAssign
      );
    },
    schoolFinanceDriverEditable() {
      if (!this.me.is.schoolFinance) return true;

      return this.permissions?.schoolFinance?.changeDrivers === true;
    },
    canUseVehicleLocationDropdown() {
      return this.canAssign && (this.isAdmin || this.vehicleOwnerCanUseVehicleDropdown);
    },
    currentLocationIndex() {
      return this.vehicleLocations.findIndex((location) => location.id === this.reserveFromLocation);
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    isLastVehicleLocation() {
      return this.currentLocationIndex === this.lastVehicleLocationIndex;
    },
    isReserveDriverDisabled() {
      return this.driver.details === null || !this.canAssign;
    },
    lastVehicleLocationIndex() {
      return -1 & (this.vehicleLocations.length - 1);
    },
    vehicleOwnerCanUseVehicleDropdown() {
      return (
        (this.me.is.specialNeedsVehicleOwner && this.permissions.specialNeedsVehicleOwner.anyVehicleLocation) ||
        (this.me.is.vehicleOwner && this.permissions.vehicleOwner.anyVehicleLocation)
      );
    },
    tripLocationName() {
      return this.locationsById[this.tripRequest.locationId].name;
    },
    awaitingApproval() {
      const approval = this.tripRequest.approval;
      const currentApprovalLevel = approval?.awaitingApproval?.id;

      if (!currentApprovalLevel) return false;

      const levelInApprovalLevelList = approval.requiredApprovalLevels.find(
        (level) => level.id === currentApprovalLevel
      );

      const position = approval.requiredApprovalLevels.indexOf(levelInApprovalLevelList);

      for (let i = position; i < approval.requiredApprovalLevels.length; i++) {
        if (approval.requiredApprovalLevels[i].canAssign) {
          return true;
        }
      }

      return false;
    },
    tripIsPickupDropoffOnly() {
      return {
        Pickup: !!this.tripRequest.pickupOnly,
        Dropoff: !!this.tripRequest.dropoffOnly,
      };
    },
    doLegAssignmentsNeeded() {
      const doAssignments = this.tripRequest.assignments.filter((e) => e.leg == 1);
      return doAssignments.length != this.tripRequest.numVehicles;
    },
    rLegAssignmentsNeeded() {
      const rAssignments = this.tripRequest.assignments.filter((e) => e.leg == 2);
      return rAssignments.length != this.tripRequest.numVehicles;
    },
    assignmentsNeeded() {
      if (this.tripRequest.splitTrip) return this.doLegAssignmentsNeeded || this.rLegAssignmentsNeeded;
      else if (!this.tripRequest.assignments.length && !this.tripRequest.numVehicles) return false;
      else return this.tripRequest.assignments.length < this.tripRequest.numVehicles;
    },
    driversNeeded() {
      if (this.assignments.length) {
        const driverNeeded = this.assignments.filter((assignment) => assignment.driverId == 0);
        return driverNeeded.length ? true : false;
      }
      return true;
    },
    vehiclesNeeded() {
      if (this.assignments.length) {
        const vehicleNeeded = this.assignments.filter((assignment) => assignment.vehicleId == 0);
        return vehicleNeeded.length ? true : false;
      }
      return true;
    },
    maxAssignments() {
      return this.tripRequest.numVehicles;
    },
    tripZone() {
      let zone;

      if (this.tripRequestConfig.other.determineZoneBy === 'request')
        zone = this.locationsById[this.tripRequest.locationId].zone;
      else if (this.tripRequestConfig.other.determineZoneBy === 'vehicle')
        zone = this.locationsById[this.reserveFromLocation].zone;

      return zone || 'no zone';
    },
    tripHours() {
      const start = new Date(`${this.tripRequest.leaveDate}, ${this.tripRequest.leaveTime}`);
      const end = new Date(`${this.tripRequest.returnDate}, ${this.tripRequest.returnTime}`);
      const diff = differenceInMinutes(end, start);

      return Math.round((diff / 60 + Number.EPSILON) * 100) / 100;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('assignment', [SAVE_ASSIGNMENT, DELETE_ASSIGNMENT]),
    ...mapActions('mechanic', [GET_MECHANICS, SAVE_MECHANIC]),
    ...mapActions('location', [GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS]),
    ...mapActions('tripRequest', [UPDATE_ASSIGNMENT_LOCATION]),
    ...mapMutations('assignment', ['updateAssignment', 'removeAssignmentById']),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    resetDriver() {
      this.driver.email = null;
    },
    async reserveDriver() {
      const { details } = this.driver;

      const isDriverFromDb = !!details.id;
      const driverName = `${details?.firstName} ${details?.lastName}`;
      const assignment = [
        {
          tripRequestId: this.tripRequest.id,
          vehicleId: 0,
          override: false,
          driverId: details?.id || 0,
          driver: isDriverFromDb ? driverName : details,
          driverEmail: this.driver.email || details?.email || null,
        },
      ];

      await this.reserve(assignment);

      this.resetDriver();
    },
    setDriverEmail(assignment) {
      if (!assignment.driver.id) return;

      assignment.driverEmail = assignment.driver.email;
    },
    getDriverEmail({ driverId, driverEmail }) {
      if (driverEmail) return driverEmail;

      if (!driverId) return null;

      const driver = this.driversById[driverId];

      if (!driver) return null;

      return driver.email;
    },
    showDriverDeleteButton({ assignment }) {
      const isNormalVehicle = this.tripRequest.vehicleType === this.VEHICLE_TYPES.NORMAL;
      const needsNoDriver = !this.tripRequest.needDriverAssigned;

      const hasAdminPrivileges =
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner;

      const hasAssignedDriver = Boolean(assignment.driverId || assignment.driver || assignment.driverEmail);

      return isNormalVehicle && needsNoDriver && hasAdminPrivileges && hasAssignedDriver;
    },
    getDriverName({ assignment }) {
      if (assignment.driverId && this.driversById[assignment.driverId]?.firstName) {
        return `${this.driversById[assignment.driverId].firstName} ${this.driversById[assignment.driverId].lastName}`;
      }

      return assignment.driver;
    },
    async init() {
      this.requestLocationVehicleAssignments();

      if (!this.tripRequest.assignments) this.tripRequest.assignments = Array.from(Array(this.tripRequest.numVehicles));

      this.getReserveFromLocation();

      const activeDrivers = this.drivers.filter((driver) => driver.active);

      this.driverItems = [{ header: 'Select an existing Driver or enter a name' }, ...activeDrivers];

      if (this.tripRequest.vehicleType === this.VEHICLE_TYPES.NORMAL) return;

      await this.getMechanics();

      this.mechanicItems = [{ header: 'Select an existing Mechanic or enter a name' }, ...this.mechanicsList];
    },
    async requestLocationVehicleAssignments() {
      if (this.canUseVehicleLocationDropdown) {
        this.vehicleLocations = this.stateVehicleLocations;
      } else {
        this[GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS](this.tripRequest.locationId)
          .then((response) => (this.vehicleLocations = response))
          .catch(() => {});
      }
    },
    getReserveFromLocation() {
      this.reserveFromLocation = this.tripRequest.assignmentLocationId || this.tripRequest.locationId || 0;

      const owner = getVehicleOwner(this.tripRequest);

      if (owner) {
        this.reserveFromLocationVehicleOwner = owner.displayName
          ? `${owner.displayName} (${owner.email})` || owner.email
          : '';
      }
    },
    handleLocationChange(locationId) {
      this.disableAssignments = !(
        this.isAdmin ||
        (this.me.is.vehicleOwner && this.permissions.vehicleOwner.assignAllLocations) ||
        (this.me.is.specialNeedsVehicleOwner && this.permissions.specialNeedsVehicleOwner.assignAllLocations)
      );

      this.$refs.fav.getItems();

      const owner = getVehicleOwner({ ...this.tripRequest, assignmentLocationId: locationId });

      if (owner)
        this.reserveFromLocationVehicleOwner = owner.displayName
          ? `${owner.displayName} (${owner.email})` || owner.email
          : '';

      this.reserveLocationChanged = true;
    },
    refresh() {
      this.$emit('refreshTripRequest');
      this.$refs.fad.getItems();
      this.$refs.fav.getItems();
      this.$emit('refreshTripRequests');
    },
    async nextLocation(useSelected) {
      if (this.savingReserveLocation || this.updatingReserveLocation) return;

      if (useSelected) this.savingReserveLocation = true;
      else this.updatingReserveLocation = true;

      let nextLocationId = 0;

      if (useSelected) {
        nextLocationId = this.reserveFromLocation;
      } else {
        const nextLocationIndex = this.currentLocationIndex + 1;
        const nextLocation = this.vehicleLocations[nextLocationIndex];

        nextLocationId = nextLocation.id;
      }

      try {
        const r = await this.updateAssignmentLocation({
          tripRequestId: this.tripRequest.id,
          assignmentLocationId: nextLocationId,
        });

        if (r && r.done) {
          this.updateTripRequest({ ...this.tripRequest, assignmentLocationId: nextLocationId });

          const owner = this.vehicleOwners.find((e) => e.locationId == nextLocationId);

          if (owner) {
            this.reserveFromLocationVehicleOwner = owner.displayName
              ? `${owner.displayName} (${owner.userEmail})` || owner.userEmail
              : '';
          }

          this.$myalert.success('Assignment Location changed & Vehicle Owner notified', true);
          this.$emit('refreshTripRequest');
          this.reserveFromLocation = nextLocationId;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.reserveLocationChanged = false;
        this.savingReserveLocation = false;
        this.updatingReserveLocation = false;
      }
    },
    async openFAV(updateId, leg) {
      this.isLoadingVehicle = true;
      this.$refs.fav.assignmentId = updateId;
      this.$refs.fav.leg = leg;
      await this.$refs.fav.getItems();
      this.isLoadingVehicle = false;
      this.$refs.fav.dialog = true;
    },
    async openFAD(updateId, leg, assistant) {
      this.isLoadingFAD = true;

      this.$refs.fad.assignmentId = updateId;
      this.$refs.fad.leg = leg;
      this.$refs.fad.assistant = assistant;
      this.$refs.fad.max = assistant ? 1 : this.maxAssignments;
      await this.$refs.fad.getItems();
      this.isLoadingFAD = false;
      this.$refs.fad.dialog = true;
    },
    async addAgencyAssignments() {
      const assignments = [];
      const at = this.additionalTransportationsById[this.tripRequest.additionalTransportationId];
      for (let i = 0; i < this.tripRequest.numVehicles; i++)
        assignments.push({
          tripRequestId: this.tripRequest.id,
          vehicleId: 0,
          vehicle: `${at.type[0].toUpperCase() + at.type.slice(1)} (${at.name})`,
          driver: 'Provided',
        });
      await this.reserve(assignments);
    },
    async removeVehicle(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this vehicle?');
      if (ok)
        if (!assignment.driverId && !assignment.driver) await this.removeAssignment(assignment.id, true);
        else
          try {
            this.isLoading = true;
            const obj = {
              id: assignment.id,
              vehicleId: 0,
              raw: true,
            };
            await this[SAVE_ASSIGNMENT](obj);
            this.$myalert.success('Vehicle removed');
            this.refresh();
            await this.updateAssignment({ ...assignment, vehicleId: 0 });
          } catch (error) {
            this.$myalert.error(error.message);
          } finally {
            this.isLoading = false;
          }
    },
    async removeDriver(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this driver?');
      if (ok)
        if (!assignment.vehicleId) await this.removeAssignment(assignment.id, true);
        else
          try {
            const obj = {
              id: assignment.id,
              driverId: 0,
              driver: null,
              driverEmail: null,
              removed: true,
              raw: true,
            };
            await this[SAVE_ASSIGNMENT](obj);
            this.$myalert.success('Driver removed');
            this.refresh();
            await this.updateAssignment({ ...assignment, driverId: 0, driver: null, driverEmail: null });
          } catch (error) {
            this.$myalert.error(error.message);
          }
    },
    async removeMechanic(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this mechanic?');
      if (ok)
        try {
          const obj = {
            id: assignment.id,
            mechanic: null,
            mechanicEmail: null,
            removed: true,
            raw: true,
          };
          await this[SAVE_ASSIGNMENT](obj);
          this.$myalert.success('Mechanic removed');
          this.refresh();
          await this.updateAssignment({ ...assignment, mechanic: null, mechanicEmail: null });
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
    async removeAssistant(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this assistant?');

      if (ok)
        try {
          const obj = {
            id: assignment.id,
            assistantId: 0,
            removed: true,
            raw: true,
          };

          await this[SAVE_ASSIGNMENT](obj);

          this.$myalert.success('Assistant removed');

          this.refresh();

          await this.updateAssignment({ ...assignment, assistantId: 0 });
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
    declineDriver() {},
    async reserve(objs) {
      this.isLoading = true;
      this.reserving = true;

      try {
        for (let obj of objs) {
          this.handleDriverAdded(obj);

          const { enableSave, ...assignment } = obj;

          await this[SAVE_ASSIGNMENT](assignment);
        }

        this.$myalert.success(`Assignment${objs.length > 1 ? 's' : ''} saved`);

        this.refresh();
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.isLoading = false;
        this.reserving = false;
      }
    },
    async saveAssignmentMechanic(objs) {
      this.isLoading = true;
      this.reserving = true;

      try {
        for (let obj of objs) {
          const response = await this[SAVE_ASSIGNMENT](obj);

          if (response?.id && obj.mechanic && obj.mechanicEmail && !obj.mechanicId) {
            await this.saveMechanic({ name: obj.mechanic, email: obj.mechanicEmail });
          }
        }

        this.$myalert.success(`Assignment${objs.length > 1 ? 's' : ''} saved`);

        this.refresh();
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.isLoading = false;
        this.reserving = false;
      }
    },
    async removeAssignment(id, override) {
      const ok = override ? true : await this.$myconfirm('Are you sure you want to remove this assignment?');
      if (ok)
        try {
          this.isLoading = true;
          await this.deleteAssignment(id);
          this.$myalert.success('Assignment deleted');
          this.refresh();
          this.removeAssignmentById(id);
        } catch (error) {
          this.$myalert.error(error.message);
        } finally {
          this.isLoading = false;
        }
    },
    getLocationText(location) {
      return location.name + ' (' + location.code + ')';
    },
    getDriverText(driver) {
      return [driver?.firstName, driver?.lastName].join(' ').trim();
    },
    getMechanicText(mechanic) {
      return mechanic?.name || '';
    },
    getVehicle(assignment) {
      if (assignment.vehicleId) return this.vehiclesById[assignment.vehicleId].name;
      else return assignment.vehicle;
    },
    handleDriverAdded(assignment) {
      const isDriverManuallyTyped = assignment.driver?.id === undefined;

      const driver = {
        id: assignment.driver?.id,
        email: assignment.driverEmail || assignment.driver?.email,
        name: isDriverManuallyTyped ? assignment.driver : this.getDriverText(assignment.driver),
      };

      if (!driver.name) return;

      if (driver.id) assignment.driverId = driver.id;

      assignment.driver = driver.name;
      assignment.driverEmail = driver.email || null;
      assignment.enableSave = true;
    },
    handleMechanicAdded(mechanic, assignment) {
      if (mechanic?.id) {
        assignment.mechanic = mechanic.name;
        assignment.mechanicEmail = mechanic.email;
        assignment.mechanicId = mechanic.id;
      }
    },
    showVehicleAndDriverAssignment() {
      // validate split trip date
      const errorMessage = validateSplitTripDates(this.tripRequest);
      if (errorMessage) {
        this.$myalert.error(errorMessage);
      } else {
        this.showVehicleAndDriverAssignmentButtons = true;
      }
    },
  },
};
</script>

<style scoped>
.leg-label {
  display: inline-block;
  width: 90px;
  margin-right: 10px;
}
</style>
