<template>
  <v-btn text color="primary" @click="download()"> <v-icon>mdi-download</v-icon> {{ label }} </v-btn>
</template>

<script>
import { mapActions } from 'vuex';
import { DOWNLOAD_TRIP_TICKET } from '@/store/modules/TripRequest/actions';
import { randomString } from '@/util';

export default {
  inject: ['eventHub'],
  components: {},
  props: {
    assignmentIds: Array,
  },
  computed: {
    label() {
      return this.assignmentIds.length > 1 ? 'All Trip Tickets' : 'Trip Ticket';
    },
  },
  methods: {
    ...mapActions('tripRequest', [DOWNLOAD_TRIP_TICKET]),
    async download() {
      if (this.assignmentIds.length) {
        const includeDirections = await this.$myconfirm(
          `Include directions in the Trip Ticket${this.assignmentIds.length > 1 ? 's' : ''}?`
        );
        try {
          await this.downloadTripTicket({
            assignmentIds: this.assignmentIds,
            uuid: randomString(16),
            reportId: 0,
            includeDirections,
          });
        } catch (error) {
          this.$myalert.error(error.message);
        }
      } else this.$myalert.error('There are no Assignments to create Trip Tickets for');
    },
  },
};
</script>

<style lang="scss"></style>
