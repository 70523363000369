<template>
  <v-container fluid>
    <v-form class="full-width">
      <div>
        <template v-if="showFundingSources">
          <v-row>
            <v-col cols="12" md="12">
              <v-btn color="primary" @click="addFundingSource" :disabled="addFSDisabled"
                ><v-icon>mdi-plus</v-icon>{{ fundingSourceLabel }}</v-btn
              >
            </v-col>

            <v-subheader v-if="fsRequired">A Funding Source is required for the selected Trip Type</v-subheader>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12">
              <v-card v-for="(item, index) in tripRequest.fundingSources" :key="index" class="mb-4">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          :disabled="tripRequest.fundingSources[index].delete"
                          :items="filteredFundingSources(tripRequest.fundingSources[index].fundingSourceId)"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :label="`Funding Source #${index + 1}`"
                          :readonly="cannotModifyFields"
                          :ref="'fs' + index"
                          :hint="
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId]
                              ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].description
                              : ''
                          "
                          :rules="[
                            () => !!tripRequest.fundingSources[index].fundingSourceId || 'This field is required',
                          ]"
                          @change="handleFSSelect(item, index)"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          required
                          v-model="tripRequest.fundingSources[index].fundingSourceId"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                          fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 3
                        "
                      >
                        <v-select
                          :disabled="tripRequest.fundingSources[index].delete"
                          :items="filteredBudgetCodes"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :readonly="cannotModifyFields"
                          item-text="description"
                          label="Budget Code"
                          v-model="tripRequest.fundingSources[index].budgetCode"
                          item-value="codeStr"
                          return-object
                          @input="
                            (val) => {
                              setTripFunding(val, index);
                            }
                          "
                        />
                        <div>
                          {{ getBudgetCodeName(tripRequest.fundingSources[index].budgetCodeId) }}
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                          (fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 1 ||
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 2 ||
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 4)
                        "
                      >
                        <BudgetCodeWithLocation
                          :locationId="Number(tripRequest.locationId)"
                          :fundingSource="tripRequest.fundingSources[index]"
                          v-if="
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type !== 4 &&
                            isCurrentTripFundingNoBudgetCode({ fundingSourceIndex: index })
                          "
                          :useInputProps="false"
                          @input="
                            (val) => {
                              setTripFunding(val, index);
                            }
                          "
                          :disabled="tripRequest.fundingSources[index].delete === 1"
                        />
                        <v-text-field
                          v-else
                          v-model="tripRequest.fundingSources[index].budgetCode"
                          label="Budget Code"
                          :readonly="
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 1 ||
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 2 ||
                            (fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].type == 4 &&
                              !fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].codeIsEditable) ||
                            cannotModifyFields
                          "
                          :disabled="tripRequest.fundingSources[index].delete"
                          :value="tripRequest.fundingSources[index].budgetCode"
                          :hint="
                            isBudgetCodeEditable(index)
                              ? 'Editable budget code, press enter to save new budget code'
                              : ''
                          "
                          @keyup.enter="handleUpdateBudgetCode(index)"
                        />

                        <div>
                          {{ getBudgetCodeName(tripRequest.fundingSources[index].budgetCodeId) }}
                        </div>

                        <v-alert color="warning" dark v-if="tripRequest.fundingSources[index].isBudgetCodeDeleted">
                          <v-icon left>mdi-alert-circle</v-icon>
                          {{ removedBudgetCodeMessage }}
                        </v-alert>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" md="4">
                        <div
                          v-if="showApproverInfo(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])"
                          class="text-subtitle-2 font-weight-bold"
                        >
                          Funding Approver:
                        </div>

                        <template
                          v-if="
                            showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])
                          "
                        >
                          <div class="text-subtitle-2 font-weight-bold">Est Trip Cost:</div>
                          <div class="text-subtitle-2 font-weight-bold">Est Balance:</div>
                        </template>
                      </v-col>

                      <v-col cols="12" md="8">
                        <div
                          v-if="showApproverInfo(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])"
                          class="text-subtitle-2 font-weight-normal"
                        >
                          {{ fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].approverUsername }}
                          ({{ fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].approverEmail }})
                        </div>

                        <template
                          v-if="
                            showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])
                          "
                        >
                          <div class="text-subtitle-2 font-weight-normal">{{ toMoney(estTripCost) }}</div>
                          <div class="text-subtitle-2 font-weight-normal">
                            {{
                              toMoney(
                                estimateBalance(
                                  fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                                    fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                                    ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                                        .allotment
                                    : 0
                                )
                              )
                            }}
                          </div>
                        </template>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        v-if="
                          showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId]) &&
                          estimateBalance(
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                              fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                              ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget.allotment
                              : 0
                          ) < 0
                        "
                      >
                        <v-alert outlined type="error" text>{{ tripRequestConfig.messages.allotmentDepleted }}</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn :disabled="cannotModifyFields" @click="deleteFundingSource(item, index)" color="error">
                    <v-icon left>
                      {{ item.delete ? 'mdi-refresh' : 'mdi-delete' }}
                    </v-icon>
                    {{ item.delete ? 'Undo' : 'Delete' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-if="tripRequestConfig.display.thirdPartyPayment">
          <v-row class="mt-4">
            <v-col cols="12" md="10">{{ tripRequestConfig.labels.thirdPartyPayment }}</v-col>

            <v-col cols="12" md="2">
              <div class="d-flex align-center justify-center">
                <span>No</span>
                <v-switch
                  class="ml-4"
                  v-model.number="tripRequest.payableToThirdParty"
                  :true-value="1"
                  :false-value="0"
                  inset
                  :readonly="cannotModifyFields"
                  @change="handleThirdParty"
                ></v-switch>
                <span>Yes</span>
              </div>
            </v-col>
          </v-row>

          <template v-if="tripRequest.payableToThirdParty == 1">
            <v-row dense class="ml-1">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="tripRequest.thirdPartyAmount"
                  label="Amount"
                  type="number"
                  min="0"
                  prepend-icon="mdi-currency-usd"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <template v-slot:label>
                  <div>Payment Option</div>
                </template>

                <v-radio-group v-model="tripRequest.thirdPartyOption" :readonly="cannotModifyFields">
                  <v-radio
                    v-for="option in filteredPayments"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="6">
                <v-subheader>Payment Due To</v-subheader>

                <v-row dense>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="tripRequest.thirdPName"
                      label="Name"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="tripRequest.thirdPAddress"
                      label="Address"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="tripRequest.thirdPCity"
                      label="City"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="tripRequest.thirdPState"
                      label="State"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="tripRequest.thirdPZip"
                      label="Zip"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="ml-1" v-if="tripRequest.thirdPartyOption == 2">
              <v-col cols="12" md="6">
                <v-text-field
                  label="P-Card Account Number"
                  v-model="tripRequest.pcardAccount"
                  outlined
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="ml-1">
              <v-col cols="12" md="6">
                <v-text-field
                  label="Purchase Order / Requisition Number"
                  v-model="tripRequest.thirdPartyPO"
                  outlined
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="ml-1">
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="tripRequest.thirdPartyComments"
                  label="Comments Concerning Payment"
                  dense
                  outlined
                  rows="3"
                  :readonly="cannotModifyFields"
                ></v-textarea>
              </v-col>
            </v-row>
          </template>
        </template>

        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="cannotModifyFields"
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  toMoney,
  SINGLE_BUDGET_CODE_IDS,
  getBudgetCodeDescriptionWithLocation,
  addBudgetCodeStrings,
  fillInBudgetCodes,
} from '@/util';
import { fundingSourceTypesMap } from '@/util/constants';
import { removedBudgetCodeMessage } from '@/util/messages';

import { TRIP_STATUS } from '@/shared/common';

import { GET_BUDGET_CODES } from '@/store/modules/BudgetCode/actions';

import BudgetCodeWithLocation from '@/components/BudgetCode/BudgetCodeWithLocation.vue';
import CustomFormField from './CustomFormField.vue';

export default {
  name: 'FundingSource',
  inject: ['eventHub'],
  components: { CustomFormField, BudgetCodeWithLocation },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    customFormFields: Array,
    step: Number,
    readonly: Boolean,
  },
  data() {
    return {
      budgetCodes: [],
      filteredBudgetCodes: [],
      locations: [],
      codeStrs: [],
      SINGLE_BUDGET_CODE_IDS: SINGLE_BUDGET_CODE_IDS,
      removedBudgetCodeMessage,
    };
  },
  computed: {
    ...mapGetters('fundingSource', ['fundingSources', 'filterFundingSources', 'fundingSourcesById']),
    ...mapGetters('user', ['me']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('thirdPartyPayment', ['thirdPartyPayments']),
    ...mapGetters('budgetCode', ['budgetCodesByLocation']),
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('tripRequest', [
      'currentTripLocation',
      'currentTripRequest',
      'isCurrentTripFundingNoBudgetCode',
      'isTripApprovedAndSubmittedByRequester',
    ]),
    fundingSourceLabel() {
      return this.tripRequestConfig.labels?.addFundingSource || 'add funding source';
    },
    cannotModifyFields() {
      // specific checks for role requester -- add here for more role checking
      const additionalChecking = this.isTripApprovedAndSubmittedByRequester;

      return (
        additionalChecking &&
        ((this.readonly && !this.tripRequest.permissions.canEditSiteAdmin) ||
          this.fsRequired ||
          ((!this.tripRequest.permissions.canEditPreApproval || !this.tripRequest.permissions.canEditMidApproval) &&
            !Number.isInteger(this.step) &&
            this.tripRequest.status !== TRIP_STATUS.DRAFT))
      );
    },
    fsRequired() {
      const requesterRequiresFundingSource =
        this.tripRequestConfig?.display?.showFunding &&
        (this.tripRequestConfig?.display?.requesterFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

      const approverRequiresFundingSource =
        this.me.is.approver &&
        this.tripRequest.status !== TRIP_STATUS.DRAFT &&
        (this.tripRequestConfig?.display?.approverFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

      return approverRequiresFundingSource || requesterRequiresFundingSource;
    },
    addFSDisabled() {
      const fundingSourceLimit = this.tripRequestConfig.display.fundingSourceLimit || 3;

      if (
        this.readonly ||
        (this.tripRequest.fundingSources.length >= fundingSourceLimit && this.fsRequired) ||
        (this.tripRequest.status == TRIP_STATUS.SUBMITTED &&
          !this.me.is.superAdmin &&
          !this.me.is.transportationAdmin &&
          !this.tripRequest.permissions.canApprove &&
          (this.tripRequest.permissions.canEditMidApproval || this.tripRequest.permissions.canEditApproved))
      ) {
        return true;
      }

      return false;
    },
    estTripCost() {
      return 123.45;
    },
    showFundingSources() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.tripRequestConfig.display.showFunding ||
        this.fsRequired
      );
    },
    filteredPayments() {
      return this.thirdPartyPayments.filter((option) => {
        return option.id !== 2 || (option.id === 2 && this.tripRequestConfig.display.pcardOption);
      });
    },
  },
  created() {
    this.eventHub.$on('validateStepFundingSource', (reset) => this.validate(reset));
    if (this.tripRequest.tripTypeId) this.handleExistingFS();
    else this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepFundingSource');
  },
  methods: {
    ...mapActions('fundingSource', ['saveFundingSource']),
    ...mapActions('budgetCode', [GET_BUDGET_CODES]),
    ...mapActions('thirdPartyPayment', ['getThirdPartyPayments']),
    ...mapActions('tripRequest', ['updateTripRequest']),
    toMoney,
    getBudgetCodeName(budgetCodeId) {
      return this.budgetCodes.find((bc) => bc.id === budgetCodeId)?.name;
    },
    isTripFundingSourceValid({ fundingSourceId }) {
      const allowedLocationIds = this.fundingSourcesById[fundingSourceId]?.locations;

      if (!allowedLocationIds || allowedLocationIds?.length === 0) return true;

      return allowedLocationIds.includes(this.currentTripRequest?.locationId);
    },
    setTripFunding(val, index) {
      this.tripRequest.fundingSources[index].budgetCode = val.text || val.description;
      this.tripRequest.fundingSources[index].budgetCodeId = val.value || val.id;
    },
    async fetchItems() {
      const codes = await this.getBudgetCodes();
      this.budgetCodes = addBudgetCodeStrings(codes);
      this.locations = this.schools;
      await this.getThirdPartyPayments();
    },
    filteredFundingSources(selected) {
      return this.filterFundingSources({
        tripTypeId: this.tripRequest.tripTypeId,
        locationId: this.tripRequest.locationId,
        existingFundingSourceIds: new Set(this.tripRequest.fundingSources.map((fs) => fs.fundingSourceId)),
        selected,
      });
    },
    addFundingSource() {
      if (!this.tripRequest.fundingSources) this.tripRequest.fundingSources = [];
      this.tripRequest.fundingSources.push({
        fundingSourceId: 0,
        budgetCode: '',
      });
    },
    deleteFundingSource(item, index) {
      if (item.id) {
        item.delete = !item.delete;
        this.tripRequest.fundingSources.push({});
        this.tripRequest.fundingSources.pop();
      } else this.tripRequest.fundingSources.splice(index, 1);
    },
    async handleExistingFS() {
      await this.fetchItems();
      if (this.tripRequest.fundingSources && this.tripRequest.fundingSources.length) {
        let index = 0;
        for (let fs of this.tripRequest.fundingSources) {
          if (fs && fs.fundingSourceId) this.handleFSSelect(fs.fundingSourceId, index);
          index++;
        }
      }
    },
    handleFSSelect(item, index) {
      const fs = this.getFundingSource(item);
      const originalFsDetails = this.getOriginalFsDetails(fs);

      const clonedTripFundingSource = JSON.parse(JSON.stringify(this.tripRequest.fundingSources[index]));
      const tripFundingSource = this.tripRequest.fundingSources[index];

      this.resetTripFundingSource(tripFundingSource);

      this.assignBudgetCodeIfMissing(fs, clonedTripFundingSource);

      const locationCode = this.getLocationCode();

      switch (fs.type) {
        case fundingSourceTypesMap.singleBudgetCode:
        case fundingSourceTypesMap.singleBudgetCodeAllotments:
          clonedTripFundingSource.budgetCode = this.getSingleBudgetCodeDescription(fs, locationCode);
          break;
        case fundingSourceTypesMap.multipleBudgetCodes:
          this.handleTypeThree(fs, locationCode);
          break;
        case fundingSourceTypesMap.editableBlank:
          this.handleTypeFour(tripFundingSource, originalFsDetails);
          break;
        default:
          break;
      }

      this.updateTripFundingSource(tripFundingSource, fs, clonedTripFundingSource);

      this.restoreOriginalFsDetails(fs, originalFsDetails);
    },

    getFundingSource(item) {
      return Number.isInteger(item) ? this.fundingSourcesById[item] : this.fundingSourcesById[item.fundingSourceId];
    },

    getOriginalFsDetails(fs) {
      return {
        budgetCode: fs.budgetCode,
        budgetCodeId: fs.budgetCodeId,
      };
    },

    resetTripFundingSource(tripFundingSource) {
      tripFundingSource.budgetCode = '';
      tripFundingSource.budgetCodeID = undefined;
    },

    assignBudgetCodeIfMissing(fs, clonedTripFundingSource) {
      if (!fs.budgetCodeId) {
        fs.budgetCodeId = clonedTripFundingSource.budgetCodeId;
        fs.budgetCode = clonedTripFundingSource.budgetCode || fs.budgetCodeDescription;
      }
    },

    getLocationCode() {
      return this.locationsById[this.tripRequest.locationId].code;
    },

    getSingleBudgetCodeDescription(fs, locationCode) {
      return getBudgetCodeDescriptionWithLocation({
        fundingSource: fs,
        locationCode,
        budgetCodeConfig: this.budgetCodeConfig,
        budgetCodes: this.budgetCodes,
      });
    },

    handleTypeThree(fs, locationCode) {
      const index = this.budgetCodeConfig.findIndex((config) => config.locationDependent === true);
      if (index === -1) this.filteredBudgetCodes = [];
      else {
        this.filteredBudgetCodes = this.budgetCodes.filter((bc) => {
          const codes = Object.values(bc.code);
          const codesString = codes.join(' ');
          return !codesString.includes('?') && +codes[index] === +locationCode.toString();
        });
      }

      this.filteredBudgetCodes = fillInBudgetCodes(this.filteredBudgetCodes, locationCode);
      this.codeStrs = this.filteredBudgetCodes.map((e) => e.codeStr);
    },

    handleTypeFour(tripFundingSource, originalFsDetails) {
      tripFundingSource.budgetCode = originalFsDetails?.budgetCode || originalFsDetails?.budgetCodeDescription;
    },

    updateTripFundingSource(tripFundingSource, fs, clonedTripFundingSource) {
      tripFundingSource.isBudgetCodeDeleted = fs.isBudgetCodeDeleted;
      tripFundingSource.budgetCodeId = fs.budgetCodeId;
      if (fs.type !== 4) {
        tripFundingSource.budgetCode =
          clonedTripFundingSource.budgetCode || fs.budgetCode || tripFundingSource.budgetCode;
      }
    },

    isBudgetCodeEditable(index) {
      const fundingSource = this.fundingSourcesById[this.tripRequest.fundingSources[index].fundingSourceId];
      return fundingSource.type == 4 && fundingSource.codeIsEditable
        ? 'Editable budget code, press enter to save new budget code'
        : '';
    },

    restoreOriginalFsDetails(fs, originalFsDetails) {
      fs.budgetCode = originalFsDetails.budgetCode;
      fs.budgetCodeId = originalFsDetails.budgetCodeId;
    },

    showApproverInfo(fs) {
      if (fs && fs.approverId) return true;
      else return false;
    },
    showAllotmentBalance(fs) {
      return false; // temporary
      // if (fs && fs.allotmentsUsed) return true;
      // else return false;
    },
    estimateBalance(currBalance) {
      return currBalance - this.estTripCost;
    },
    handleThirdParty(selected) {
      if (selected) {
        setTimeout(() => {
          const div = document.getElementById('form-content');
          div.scrollTop = div.scrollHeight;
        }, 100);
      }
    },
    validate(reset) {
      if (!reset) {
        this.tripRequest.fundingSources.forEach((e, i) => this.$refs['fs' + i][0].validate(true));

        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        this.tripRequest.fundingSources.forEach((e, i) => this.$refs['fs' + i][0].resetValidation());

        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
    async handleUpdateBudgetCode(index) {
      try {
        await this.saveFundingSource({
          ...this.fundingSourcesById[this.tripRequest.fundingSources[index].fundingSourceId],
          budgetCode: this.tripRequest.fundingSources[index].budgetCode,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.fetchItems();
      }
    },
  },
  watch: {
    async tripRequest(value) {
      if (value.tripTypeId) this.handleExistingFS();
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
