<template>
  <v-col :cols="isMobile ? 12 : cff.size">
    <div class="" v-if="cff.type == 0">
      <v-text-field
        ref="cff"
        v-model="val"
        :value="val"
        :label="cff.name"
        @input="handleCFFInput"
        :rules="cff.required ? [() => !!val || 'This field is required'] : []"
        :required="cff.required"
      >
      </v-text-field>
      <span v-if="cff.required"><small class="red--text">*Required</small></span>
    </div>
    <div v-if="cff.type == 1" class="">
      <v-text-field
        ref="cff"
        v-model="val"
        :value="val"
        :label="cff.name"
        type="number"
        min="0"
        @input="handleCFFInput"
        :rules="cff.required ? [() => (val && val >= 0) || 'This field is required'] : []"
        :required="cff.required"
      ></v-text-field>
      <span v-if="cff.required"><small class="red--text">*Required</small></span>
    </div>
    <div v-if="cff.type == 2">
      <date-picker
        ref="cff"
        :value="val"
        :label="cff.name"
        @input="handleCFFInput"
        :readonly="readonly"
        :rules="cff.required ? [() => !!val || 'This field is required'] : []"
        :required="cff.required"
      ></date-picker>
      <span v-if="cff.required"><small class="red--text">*Required</small></span>
    </div>
    <div v-if="cff.type == 3">
      <v-checkbox ref="cff" :value="val" v-model="val" :label="cff.name" @change="handleCFFInput"></v-checkbox>
      <span v-if="cff.required"><small class="red--text">*Required</small></span>
    </div>
    <div v-if="cff.type == 4">
      <v-select
        ref="cff"
        :value="val"
        v-model="val"
        :items="cff.items"
        :label="cff.name"
        @change="handleCFFInput"
        :rules="cff.required ? [() => !!val || 'This field is required'] : []"
        :required="cff.required"
        outlined
      ></v-select>
      <span v-if="cff.required"><small class="red--text">*Required</small></span>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'CustomFormField',
  components: { DatePicker },
  props: ['cff', 'value', 'readonly'],
  data() {
    return { val: null };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
  created() {
    this.val = this.value;
  },
  methods: {
    handleCFFInput(e) {
      this.val = e;
      this.$emit('handleCFFInput', e);
    },
    cffValidation(reset) {
      if (!reset) this.$refs['cff'].validate(true);
      else this.$refs['cff'].resetValidation();
    },
  },
};
</script>

<style scoped></style>
