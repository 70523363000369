/**
 *
 * @param {*} tripRequest
 * @returns a string if strip trip dates is invalid
 */
const validateSplitTripDates = (tripRequest) => {
  // vehicle pick-up & return
  if (tripRequest.dropOffLegVehPickupDate && tripRequest.dropOffLegVehPickupDate > tripRequest.leaveDate) {
    return 'Drop-Off Leg Vehicle Pick-Up date cannot be after Trip Leave date';
  } else if (
    tripRequest.dropOffLegVehPickupDate &&
    tripRequest.dropOffLegVehPickupDate == tripRequest.leaveDate &&
    tripRequest.dropOffLegVehPickupTime.localeCompare(tripRequest.leaveTime, undefined, {
      ignorePunctuation: true,
      numeric: true,
    }) > 0
  ) {
    return 'Drop-Off Leg Vehicle Pick-Up time cannot be after Trip Leave time';
  }
  if (tripRequest.returnLegVehPickupDate && tripRequest.returnLegVehPickupDate < tripRequest.returnDate) {
    return 'Return Leg Vehicle Return date cannot be before Trip Return date';
  } else if (
    tripRequest.returnLegVehPickupDate &&
    tripRequest.returnLegVehPickupDate == tripRequest.returnDate &&
    tripRequest.returnTime.localeCompare(tripRequest.returnLegVehPickupTime, undefined, {
      ignorePunctuation: true,
      numeric: true,
    }) < 0
  ) {
    return 'Return Leg Vehicle Pickup time cannot be after Trip Return time';
  }
  if (
    tripRequest.dropOffLegVehReturnDate &&
    tripRequest.dropOffLegVehPickupDate &&
    tripRequest.dropOffLegVehPickupDate > tripRequest.dropOffLegVehReturnDate
  ) {
    return 'Drop-Off Leg Vehicle Pick-Up date cannot be after Drop-Off Leg Vehicle Return date';
  } else if (
    tripRequest.dropOffLegVehReturnDate &&
    tripRequest.dropOffLegVehPickupDate &&
    tripRequest.dropOffLegVehPickupDate == tripRequest.dropOffLegVehReturnDate &&
    tripRequest.dropOffLegVehPickupTime.localeCompare(tripRequest.dropOffLegVehReturnTime, undefined, {
      ignorePunctuation: true,
      numeric: true,
    }) > 0
  ) {
    return 'Drop-Off Leg Vehicle Pick-Up time cannot be after Drop-Off Leg Vehicle Return time';
  }
  if (
    tripRequest.returnLegVehReturnDate &&
    tripRequest.returnLegVehPickupDate &&
    tripRequest.returnLegVehPickupDate > tripRequest.returnLegVehReturnDate
  ) {
    return 'Return Leg Vehicle Pick-Up date cannot be after Return Leg Vehicle Return date';
  } else if (
    tripRequest.returnLegVehReturnDate &&
    tripRequest.returnLegVehPickupDate &&
    tripRequest.returnLegVehPickupDate == tripRequest.returnLegVehReturnDate &&
    tripRequest.returnLegVehPickupTime.localeCompare(tripRequest.returnLegVehReturnTime, undefined, {
      ignorePunctuation: true,
      numeric: true,
    }) > 0
  ) {
    return 'Return Leg Vehicle Pick-Up time cannot be after Return Leg Vehicle Return time';
  }
};

export { validateSplitTripDates };
